.MuiPaper-root .MuiList-root {
  .MuiMenuItem-root,
  .select-all-wrap {
    margin-left: 0;
    position: relative;
    transition: $transition;
    padding: 8px 8px 8px 20px;
    &:hover {
      &:before {
        left: 0;
      }
    }
    &::before {
      content: '';
      position: absolute;
      left: -5px;
      top: 0;
      width: 10px;
      height: 100%;
      border-right: 5px solid $white-color;
      background: $primary-light-color;
      opacity: 1;
      transition: $transition;
    }
    .MuiListItemText-root {
      padding-left: 10px;
    }
  }
}
.MuiPaper-root .MuiList-root {
  padding: 5px 0;
}
.MuiPaper-root .MuiList-root .MuiListSubheader-root {
  padding: 0;
  .MuiButtonBase-root {
    // padding: 5px 10px !important;
    .MuiListItemText-root {
      padding-left: 0;
    }
  }
  .MuiFormControl-root {
    padding: 0 10px;
  }
}
.MuiPaper-root .MuiList-root .MuiButtonBase-root {
  &.MuiCheckbox-root {
    padding: 0px;
  }
}
.MuiTouchRipple-root {
  display: none;
}

.patient-tracker-table {
  .cell-patient {
    min-width: 122px;
  }

  .cell-cancer-site {
    min-width: 122px;
  }
  .cell-diagnosis {
    min-width: 140px;
  }
  .cell-physician {
    min-width: 140px;
  }

  .cell-consult {
    min-width: 105px;
  }

  .cell-pre-simulation-visit {
    min-width: 175px;
    text-align: center;
  }
  .cell-clinic-location {
    min-width: 160px;
  }
  .cell-brief-summary {
    min-width: 240px;
  }

  .cell-pre-treatment-workup {
    min-width: 180px;
    text-align: center;
    .MuiFormControl-root {
      min-width: 55px;
      max-width: 55px;
      width: auto;
      margin: 0;
      .MuiFormLabel-root {
        background: transparent;
        right: 0;
        top: -10px;
        &.MuiFormLabel-filled {
          top: 5px;
          transform: none;
        }
      }
      .MuiInputBase-root {
        background: transparent;
        &:before,
        &:after {
          content: none;
        }
        .MuiSelect-select {
          padding-right: 42px;
          position: relative;
          z-index: 9;
          line-height: 28px !important;
        }
        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }
      }
    }
  }

  .cell-physician-orders {
    min-width: 122px;
    text-align: center;
  }

  .cell-nursing-tasks {
    min-width: 122px;
    text-align: center;
  }

  .cell-simulation {
    min-width: 140px;
    text-align: center;
  }

  .cell-action {
    min-width: 90px;
  }
}

.patient-tracker-main-panel,
.patient-list {
  .patient-tracker-view-wrapper {
    .app-card {
      .MuiTypography-root {
        width: 100%;
      }
      .patient-tracker-text-panel {
        @include lg {
          margin-top: $mr-15;
        }
      }
    }
  }

  .patient-profile-wrapper {
    border: 1px solid $border-green-color;
    border-radius: $large-border-radius;
    padding: $pd-5;
    margin: auto;
    max-width: 140px;
    width: 100%;
    .img-wrapper {
      img {
        width: 100%;
      }
    }
  }
  .patient-card-panel {
    margin-top: $mr-20;
    .patient-card-wrapper {
      margin-bottom: $mr-20;
      border-top: 1px solid $border-color;

      .patient-card-content {
        margin-top: 20px;
      }
      .patient-card {
        margin-top: $mr-10;
        border-radius: $small-border-radius;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        transition: $transition;
        cursor: pointer;
        &:hover {
          background-color: $secondary-light-color;
          span {
            color: $primary-color !important;
          }
        }
        .patient-name-id-details {
          padding-left: $pd-20;
          .patient-details-block {
            span {
              transition: $transition;
            }
          }
        }
      }
    }
  }
}
.save-template-wrapper {
  margin: 10px;
  width: 100%;
  .saved-template {
    padding: 3px;
    border: 1px solid transparent;
    border-radius: $smaller-border-radius;
    transition: $transition;
    &:hover {
      border-color: $primary-color;
    }
  }
}

@mixin box-shadow {
  box-shadow: 1px 1px 6px $dark-gray-color !important;
  -webkit-box-shadow: 1px 1px 6px $dark-gray-color !important;
  -moz-box-shadow: 1px 1px 6px $dark-gray-color !important;
  -o-box-shadow: 1px 1px 6px $dark-gray-color !important;
}

@mixin transition {
  transition: 300ms ease-in-out;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard {
  .dashboard-wrapper {
    .btn-report {
      position: relative;
      padding-right: 30px !important;
      &:after {
        content: '';
        position: absolute;
        background: url(../../assets/images/svg-images/download-white-icon.svg) no-repeat;
        width: 14px;
        height: 14px;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto 0 auto auto;
      }
      &:hover {
        &:after {
          background: url(../../assets/images/svg-images/download-blue-icon.svg) no-repeat;
        }
      }
    }
    .mor-arrow {
      padding-right: 20px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 10px;
        width: 10px;
        transform: rotate(45deg);
        border-top: 2px solid $secondary-color;
        border-right: 2px solid $secondary-color;
      }
    }
    .profile-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
    }
    .app-card {
      height: calc(100% - 20px);
      &.total-patient-card {
        cursor: pointer;
        @include lg {
          justify-content: center;
          text-align: center;
        }
      }
      .total-patients-block {
        @include lg {
          flex-direction: column;
          .mr-20 {
            margin: 0 auto 5px !important;
          }
        }
      }
      .pre-treatment-progress-block {
        .MuiCircularProgress-root {
          background: url(../../assets/images/svg-images/pre-treatment-progress-icon.svg) no-repeat;
        }
      }
      .treatment-plan-progress-block {
        .MuiCircularProgress-root {
          background: url(../../assets/images/svg-images/treatment-plan-progress-icon.svg) no-repeat;
        }
      }
      .on-treatment-progress-block {
        .MuiCircularProgress-root {
          background: url(../../assets/images/svg-images/on-treatment-progress-icon.svg) no-repeat;
        }
      }
      .end-treatment-progress-block {
        .MuiCircularProgress-root {
          background: url(../../assets/images/svg-images/end-treatment-progress-icon.svg) no-repeat;
        }
      }
      .primary-block,
      .secondary-block {
        @include laptop {
          margin: 5px 0;
        }
      }
      .secondary-block {
        .MuiCircularProgress-root {
          color: $secondary-color;
        }
      }
      .progress-block {
        background: $primary-light-color;
        min-width: 100px;
        height: 100px;
        border-radius: $rounded-border-radius;
        @include flex-center;
        @include laptop {
          min-width: 80px;
          height: 80px;
        }
      }
      .MuiCircularProgress-root {
        width: 85px !important;
        height: 85px !important;
        transform: none !important;
        background-size: 60px !important;
        background-position: center !important;
        color: $primary-color;
        @include laptop {
          width: 70px !important;
          height: 70px !important;
          background-size: 50px !important;
        }
        .MuiCircularProgress-svg {
          transform: rotate(-90deg);
          .MuiCircularProgress-circle {
            stroke-linecap: round;
          }
        }
      }
    }
    .welcome-card {
      padding: 50px;
      background-color: $secondary-dark-color;
      background-image: url('../../assets/images/dashboard-welcome-card-right-bg-img.png'),
        url('../../assets/images/dashboard-welcome-card-left-bg-img.png');
      background-position: bottom right, bottom left;
      background-repeat: no-repeat, no-repeat;
      position: relative;
      @include lg {
        padding: 30px;
      }
      @include sm {
        background-image: none;
      }
      .welcome-card-img-wrapper {
        position: absolute;
        bottom: 0;
        @include sm {
          display: none;
        }
      }
      .mb-40 {
        @include sm {
          margin-bottom: 10px !important;
        }
      }
    }
    .profile {
      .profile-img {
        width: 80px;
        height: 80px;
      }
    }

    .income-card,
    .appointments-card,
    .completed-appointment-card {
      @include lg {
        margin-top: 20px;
      }
    }
    // .total-patient-card {
    //   background: url("../../assets/images/svg-images/dashboard-total-patient-bg.svg") no-repeat bottom right;
    // }
    .income-card {
      // background: url("../../assets/images/svg-images/dashboard-income-bg.svg")
      //   no-repeat bottom right;
    }
    .appointments-card {
      // background: url("../../assets/images/svg-images/dashboard-appointments-bg.svg")
      //   no-repeat bottom right;
    }
    .completed-appointment-card {
      // background: url("../../assets/images/svg-images/dashboard-completed-appointments-bg.svg")
      //   no-repeat bottom right;
      @include xl-min {
        margin-top: 20px;
      }
    }
    .next-patient-box {
      .patient-card {
        background-color: $primary-light-color;
        border-radius: 6px;
        padding: 10px 15px;
        width: 65%;
        .dots {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $secondary-color;
        }
      }
    }
    .doctor-list-box {
      .doctor-list-box-content {
        @include laptop-max {
          flex-wrap: wrap;
        }
        .doctor-card {
          width: 180px;
          height: auto;
          border-radius: 10px;
          border: 1px solid $border-color;
          overflow: hidden;
          .doctor-pill {
            padding: 3px 10px;
            border-radius: 20px;
            background-color: $secondary-light-color;
          }
        }
        .bottom-box {
          padding: 5px;
          background-color: $primary-light-color;
          .center-border {
            background-color: $border-color;
            width: 1px;
            height: 100%;
          }
        }
      }
    }
    .calendar {
      background-color: $secondary-light-color;
      border-radius: $small-border-radius;

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
      .MuiFormControl-root {
        .MuiInputBase-input,
        .MuiFormLabel-root {
          display: none !important;
        }
        .MuiButtonBase-root {
          background: $secondary-light-color;
          border-radius: $small-border-radius;
        }
        // .MuiInputBase-root {
        //   padding-right: 0;
        // }
      }
    }
    .date-pill {
      background-color: #f2f8ff;
      border-radius: 20px;
      .date-box {
        padding: 5px;
        border-radius: 6px;
        width: 35px;
        &.active,
        &:hover {
          background-color: $secondary-color;
          span {
            color: $white-color !important;
          }
        }
      }
    }
    .appointment-card {
      .appointment-card-wrapper {
        border-radius: 10px;
        border: 1px solid $border-color;
        overflow: hidden;
        .profile-img {
          height: 30px;
          width: 30px;
          min-width: 30px;
        }
        .top-box {
          padding: 5px 10px;
          background-color: $primary-color;
          .status-pill {
            padding: 1px 10px;
          }
        }
        .patient-detail {
          padding: 15px 10px 30px 10px;
        }
        .doctor-detail {
          padding: 10px;
        }
      }
    }
  }
}

.MuiTabs-root {
  .MuiTabs-indicator {
    background: linear-gradient(to right, $secondary-color, rgba(119, 172, 162, 0.2));
    max-width: 210px;
  }
  .MuiButtonBase-root {
    text-transform: capitalize;
    color: $gray-color;
    padding-left: 50px;
    position: relative;
    padding-right: 50px;
    border-bottom: 1px solid $border-color;
    &:last-child {
      padding-right: 10px;
    }
    &.Mui-selected {
      color: $primary-color;
      &:before {
        border-color: $primary-color;
      }
      &.clinical-info-tab {
        &:before {
          // background: url(../../assets/images/svg-images/clinicalInfo-tab-active-icon.svg)
          //     no-repeat;
        }
      }
      &.sim-request-tab {
        &:before {
          // background: url(../../assets/images/svg-images/smart-forms-icon-blue.svg)
          //     no-repeat;
        }
      }
      &.physician-tab {
        &:before {
          // background: url(../../assets/images/svg-images/physician-tab-active-icon.svg)
          //     no-repeat;
        }
      }
      &:after {
        opacity: 1;
      }
    }
    &:after {
      content: '';
      position: absolute;
      left: 14px;
      bottom: 2px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid $primary-color;
      opacity: 0;
      transition: 300ms ease-in-out;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 36px;
      height: 36px;
      border: 1px solid $dark-gray-color;
      box-shadow: inset 0 0 0 3px $primary-light-color;
      border-radius: $rounded-border-radius;
      background-position: center !important;
    }
    &.clinical-info-tab {
      &:before {
        background: url(../../assets/images/svg-images/clinicalInfo-tab-icon.svg) no-repeat;
      }
    }
    &.sim-request-tab {
      &:before {
        background: url(../../assets/images/svg-images/smart-forms-icon-gray.svg) no-repeat;
      }
    }
    &.physician-tab {
      &:before {
        // background: url(../../assets/images/svg-images/physician-tab-icon.svg)
        //     no-repeat;
      }
    }
  }
}
.chart-table-tabs {
  .MuiButtonBase-root {
    &::after,
    &::before {
      content: none;
    }
    &:last-child {
      padding: 0 50px !important;
    }
  }
}

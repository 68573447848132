// Pagination UI
.pagination-main-wrapper {
  .MuiInputBase-root {
    background-color: $white-color !important;
    border: 1px solid $border-color !important;
  }
  .MuiInputLabel-root {
    padding: 5px 0 5px 5px !important;
    font-weight: 400 !important;
  }
  .MuiStack-root {
    .MuiPagination-root {
      .MuiPagination-ul {
        position: relative;

        li {
          &:first-child {
            .MuiPaginationItem-previousNext {
              &::before {
                content: '';
                position: absolute;
                background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/left-white-arrow.svg)
                  no-repeat;
                background-color: $secondary-color;
                border-radius: 50%;
                background-position: center;
                height: 20px;
                width: 20px;
                left: 0;
              }
              &:hover {
                &::before {
                  background-color: $white-color;
                  background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/left-hover-green-arrow.svg)
                    no-repeat;
                  background-position: center;
                  border: 1px solid $secondary-color;
                }
              }
            }
          }
          &:last-child {
            .MuiPaginationItem-previousNext {
              &::after {
                content: '';
                position: absolute;
                background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/right-white-arrow.svg)
                  no-repeat;
                background-position: center;
                background-color: $secondary-color;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                right: 0;
              }
              &:hover {
                &::after {
                  background-color: $white-color;
                  background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/right-hover-green-arrow.svg)
                    no-repeat;
                  background-position: center;
                  border: 1px solid $secondary-color;
                }
              }
            }
          }
          .Mui-disabled {
          }
        }
        .Mui-disabled {
          opacity: 0.5;
        }
        .MuiPaginationItem-root {
          color: $secondary-color;
          height: 20px;
          min-width: 20px;
          font-size: $font12;
          line-height: $lheight16;
          &:hover {
            background-color: $secondary-color !important;
            color: $white-color;
          }
          &.Mui-selected {
            background-color: $secondary-color !important;
            color: $white-color;
          }
          &.MuiPaginationItem-ellipsis {
            &:hover {
              background-color: transparent !important;
              color: $gray-color;
            }
          }
          &.MuiPaginationItem-previousNext {
            position: relative;
            background-color: transparent !important;
          }

          .MuiSvgIcon-root {
            position: relative;
            path {
              display: none;
            }
          }
          .MuiTouchRipple-root {
            display: none;
          }
        }
      }
    }
  }
}

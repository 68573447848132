//date picker
.date-picker-wrapper {
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root {
      height: 32px;
      padding-right: 13px;
    }
    .MuiFormLabel-root {
      padding: 2px 25px 0 0 !important;
    }
    .MuiButtonBase-root {
      border-radius: 0 6px 6px 0;
      padding: 4px;
      min-height: auto !important;
    }
  }
}
.MuiPickersPopper-paper {
  border: 0 !important;
  @include box-shadow;
  .MuiPickersCalendarHeader-root {
    padding: 0 10px;
    position: relative;
    .MuiPickersCalendarHeader-labelContainer {
      margin: 0 auto;
      background: $primary-light-color;
      width: 70%;
      padding: 6px 0;
      border-radius: $small-border-radius;
      justify-content: center;
      .MuiButtonBase-root {
        display: none;
      }
      .MuiPickersCalendarHeader-label {
        font-size: $font14;
        font-weight: $fweight600;
      }
    }
    .MuiPickersArrowSwitcher-root {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      align-items: center;
      .MuiButtonBase-root {
        width: 20px;
        height: 20px;
        background: $primary-light-color;
        padding: 15px 20px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          background-size: 20px !important;
          width: 20px;
          height: 20px;
          bottom: 0;
          margin: auto;
          right: 0;
        }
        .MuiSvgIcon-root {
          display: none;
        }
        &.MuiIconButton-edgeEnd {
          position: absolute;
          left: 0;
          border-radius: 0 $small-border-radius $small-border-radius 0;
          &:before {
            background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/calendar-prev-icon.svg)
              no-repeat;
          }
        }
        &.MuiIconButton-edgeStart {
          position: absolute;
          right: 0;
          border-radius: $small-border-radius 0 0 $small-border-radius;
          &:before {
            background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/calendar-next-icon.svg)
              no-repeat;
          }
        }
      }
    }
  }
  .MuiDateCalendar-viewTransitionContainer {
    .MuiDayCalendar-header,
    .MuiPickersSlideTransition-root .MuiDayCalendar-weekContainer {
      justify-content: space-between;
      .MuiButtonBase-root {
        font-family: 'Poppins', sans-serif;
        transition: $transition;
        &:hover {
          background: $secondary-light-color;
        }
        &.MuiPickersDay-today,
        &.Mui-selected {
          background: $secondary-color;
          color: $white-color;
          border-color: $secondary-color;
        }
      }
      .MuiDayCalendar-weekDayLabel {
        font-size: $font12;
        font-family: 'Poppins', sans-serif;
        color: $primary-color;
        font-weight: $fweight600;
      }
    }
  }
}
.MuiInputBase-input {
  height: 30px;
  border-radius: $larger-border-radius !important;
  border: 1px solid $border-color;
  padding: 0 10px;
  &::-webkit-input-placeholder {
    color: $gray-color;
    font-size: $font12;
    font-style: italic;
  }
  &::-moz-placeholder {
    color: $gray-color;
    font-size: $font12;
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: $gray-color;
    font-size: $font12;
    font-style: italic;
  }
  &:-moz-placeholder {
    color: $gray-color;
    font-size: $font12;
    font-style: italic;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: $gray-color !important;
    font-size: $font12;
    font-style: italic;
  }
  &:focus {
    box-shadow: none;
  }
}

.MuiModal-root {
  @include flex-center;
  &.large-modal-popup {
    .MuiBox-root {
      max-width: 1340px;
      max-height: calc(100% - 150px);
      height: 100%;
    }
  }
  &.small-modal-popup {
    .MuiBox-root {
      max-height: 300px;
    }
  }
  .MuiBox-root {
    max-width: 600px;
    width: 100%;
    background: $white-color;
    border-radius: $large-border-radius;
    padding: 20px 0;
    position: relative;
    z-index: 9;
    overflow: hidden;
    max-height: 520px;
    height: calc(100% - 30px);
    padding: 10px 0;
    outline: none;
    .modal-close-wrapper {
      padding: 0 10px 10px 0;
      .modal-back-icon {
        cursor: pointer;
      }
    }
    .modal-header {
      background: $primary-color;
      padding: 8px 10px;
      .app-heading-wrapper {
        padding: 0;
      }
    }
    .modal-body {
      padding: 10px;
      max-height: calc(100% - 45px);
      overflow: auto;
    }
  }
}

// Accordion panel

.MuiAccordion-root {
  max-height: 100% !important;
  padding: 5px 0;
  border: none !important;
  border-radius: $large-border-radius !important;
  @include box-shadow;
  .MuiButtonBase-root {
    min-height: 48px !important;
    background: transparent !important;
  }
  .MuiTypography-root {
    width: 100%;
  }
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
}

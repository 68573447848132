//  dropdown scss
.date-picker-wrapper {
  .MuiSvgIcon-root {
    background: url(../../assets/images/svg-images/calendar-gray-icon.svg) no-repeat;
    background-position: center;
    path {
      display: block;
    }
  }
  .MuiFormControl-root {
    width: 100%;
    @include lg {
      margin-bottom: 0px !important;
    }
  }
}
.MuiFormControl-root {
  width: 100%;
  @include lg {
    margin-bottom: 10px !important;
  }
  .MuiInputLabel-root {
    top: -7px;
    font-size: $font12 !important;
    line-height: $lheight12 !important;
    border-color: $primary-color !important;
    color: $gray-color;
    &.MuiFormLabel-filled {
      left: 0px !important;
      top: 0;
      background: #fff;
      padding: 4px 5px !important;
    }
  }
  .MuiInputLabel-root.Mui-focused {
    top: 0;
    left: 0px !important;
    color: $primary-color !important;
    border-color: $primary-color !important;
    background: $white-color;
    padding: 0 10px;
  }
  .MuiOutlinedInput-root {
    &.Mui-disabled {
      background-color: $primary-light-color !important;
    }
  }
  .MuiOutlinedInput-root-MuiSelect-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color !important;
    }
  }
  .MuiInputBase-input {
    font-size: $font12;
    font-family: 'Poppins', sans-serif;
    border-radius: 0 !important;
    // &.Mui-disabled {
    //     background-color: $primary-light-color !important;
    // }
  }
  .MuiButtonBase-root {
    background: $primary-light-color;
    border-radius: 0;
    padding: 5px;
    transition: $transition;
    &:hover {
      background: $primary-light-color;
    }
  }
  .MuiSvgIcon-root {
    path {
      display: none;
    }
  }
  .MuiInputBase-root {
    &.Mui-focused {
      .MuiSelect-select {
        background: url(../../assets/images/svg-images/dropdown-up-arrow.svg) no-repeat !important;
        background-position: 96% !important;
        line-height: $lheight34;
      }
    }
    .MuiSelect-select {
      background: url(../../assets/images/svg-images/dropdown-down-arrow.svg) no-repeat !important;
      background-position: 96% !important;
      height: 30px;
      padding: 0px 15px;
      line-height: $lheight30;
    }
  }
}
.MuiPaper-root {
  height: auto;
  max-height: 350px !important;
  @include box-shadow;

  .MuiList-root {
    position: relative;
    height: 100%;
    .MuiListSubheader-root {
      .MuiButtonBase-root {
        padding: 0;
      }
    }
    .MuiButtonBase-root {
      font-size: $font12 !important;
      color: $gray-color !important;
      background: $white-color !important;
      // padding: 0;
      .MuiCheckbox-root {
        color: $secondary-color !important;
        border-radius: 0;
        width: 18px;
        height: 18px;
      }
      .MuiListItemText-root {
        .MuiTypography-root {
          font-size: $font12 !important;
          color: $gray-color !important;
        }
      }
    }
    .MuiFormControl-root {
      .MuiInputBase-root {
        border-radius: 50px !important;
        background: url(../../assets/images/svg-images/search-icon.svg) no-repeat;
        background-position: 96%;
      }
    }
    .MuiMenuItem-root {
      &:hover {
        background-color: $primary-light-color !important;
        border-radius: $smaller-border-radius;
        color: $primary-color !important;
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

//MuiSelector For table Filter Start
.filter-dropdown {
  margin-right: 10px;
  .MuiFormControl-root {
    width: 200px;
    margin: 5px 0 !important;
    position: relative;
    &::after {
      content: '';
      display: block;
      height: 20px;
      width: 18px;
      position: absolute;
      top: 0px;
      bottom: 0;
      margin: auto;
      left: 10px;
    }
    .MuiInputBase-root {
      &::after {
        content: '';
        display: block;
        height: 20px;
        width: 18px;
        position: absolute;
        top: 0px;
        bottom: 0;
        margin: auto;
        left: 10px;
      }
      &.cancer-site {
        &::after {
          background: url(../../assets/images/svg-images/cancer-site-filter-icon.svg) center center no-repeat;
        }
      }
      &.pre-treatment-workup {
        &::after {
          background: url(../../assets/images/svg-images/pre-treatment-workup-filter-icon.svg) center center no-repeat;
        }
      }

      &.pre-simulation-visit {
        &::after {
          background: url(../../assets/images/svg-images/pre-simulation-visit-filter-icon.svg) center center no-repeat;
        }
      }

      &.physician-orders {
        &::after {
          background: url(../../assets/images/svg-images/physician-orders-filter-icon.svg) center center no-repeat;
        }
      }
      &.nursing-tasks {
        &::after {
          background: url(../../assets/images/svg-images/nursing-tasks-filter-icon.svg) center center no-repeat;
        }
      }
      &.simulation-status {
        &::after {
          background: url(../../assets/images/svg-images/simulation-status-filter-icon.svg) center center no-repeat;
        }
      }
      &.status {
        &::after {
          background: url(../../assets/images/svg-images/status-filter-icon.svg) center center no-repeat;
        }
      }
      &.columns {
        &::after {
          background: url(../../assets/images/svg-images/columns-filter-icon.svg) center center no-repeat;
        }
      }
      &.note-status {
        &::after {
          background: url(../../assets/images/svg-images/note-status-filter-icon.svg) center center no-repeat;
        }
      }
      &.followup-scheduled {
        &::after {
          background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/followup-filter-icon.svg)
            center center no-repeat;
        }
      }
      &.date-filter {
        &::after {
          background: url(../../assets/images/svg-images/date-calendar-icon.svg) center center no-repeat;
        }
      }
      &.task-status {
        &::after {
          background: url(../../assets/images/svg-images/task-status-filter-icon.svg) center center no-repeat;
        }
      }
      &.created-by {
        &::after {
          background: url(../../assets/images/svg-images/created-by-filter-icon.svg) center center no-repeat;
        }
      }
      &.task-type {
        &::after {
          background: url(../../assets/images/svg-images/task-type-filter-icon.svg) center center no-repeat;
        }
      }
      &.re-simulation {
        &::after {
          background: url(../../assets/images/svg-images/reSim-filter-icon.svg) center center no-repeat;
        }
      }
      &.fusion {
        &::after {
          background: url(../../assets/images/svg-images/fusion-filter-icon.svg) center center no-repeat;
        }
      }
      &.dosimetryContours {
        &::after {
          background: url(../../assets/images/svg-images/dosimetryContours-filter-icon.svg) center center no-repeat;
        }
      }
      &.treatmentPlan {
        &::after {
          background: url(../../assets/images/svg-images/treatmentPlan-filter-icon.svg) center center no-repeat;
        }
      }
      &.dosimetryPlanPrep {
        &::after {
          background: url(../../assets/images/svg-images/dosimetryPlanPrep-filter-icon.svg) center center no-repeat;
        }
      }
      &.physicsConsult {
        &::after {
          background: url(../../assets/images/svg-images/physicsConsult-filter-icon.svg) center center no-repeat;
        }
      }
      &.preCheck {
        &::after {
          background: url(../../assets/images/svg-images/preCheck-filter-icon.svg) center center no-repeat;
        }
      }
      &.priorAuthorization {
        &::after {
          background: url(../../assets/images/svg-images/priorAuthorization-filter-icon.svg) center center no-repeat;
        }
      }
      &.therapyChartCheck {
        &::after {
          background: url(../../assets/images/svg-images/therapyChartCheck-filter-icon.svg) center center no-repeat;
        }
      }
      &.chart-status {
        &::after {
          background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/review-status-filter.svg)
            center center no-repeat;
        }
      }

      .MuiInputBase-input {
        padding: 0 0 0 25px;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 0 !important;
      }
    }

    .MuiInputLabel-root {
      font-weight: $fweight500;
      color: $secondary-color !important;
      padding: 3px 0 5px 25px;
      font-family: 'Poppins', sans-serif;
      font-size: $font12 !important;
      &.Mui-focused {
        top: 4px;
        padding: 2px 5px !important;
        transform: translate(14px, -9px) scale(0.75) !important;
      }
    }
    .MuiInputBase-root {
      border-radius: $larger-border-radius !important;
      background-color: $secondary-light-color !important;
      &:before,
      &:after {
        border-bottom: 0 !important;
      }
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
          border-color: $secondary-color !important;
        }
      }
      .MuiSelect-select {
        background: url(../../assets/images/svg-images/dropdown-down-arrow.svg) no-repeat !important;
        background-position: 96% !important;
        height: 30px;
        padding: 3px 15px 3px 38px;
        line-height: $lheight30;
        border: 0 !important;
      }
    }
  }
}
// .date-filter {
//     .MuiFormControl-root {
//         width: 120px !important;
//     }
// }

//  Date-filter
.rdrDateRangePickerWrapper {
  box-shadow: 1px 1px 5px rgba(196, 212, 234, 0.7);
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  .rdrCalendarWrapper {
    position: relative !important;
    z-index: 1;
    padding-bottom: 50px;
    .rdrDateDisplayWrapper {
      background-color: $white-color !important;
      .rdrDateDisplay {
        .rdrDateDisplayItem {
          border: none !important;
          background-color: $primary-light-color !important;
          input {
            color: $primary-color !important;
          }
        }
      }
    }
    .rdrMonthAndYearWrapper {
      .rdrPprevButton {
        position: relative;
        background: $primary-light-color;
        &::after {
          content: '';
          position: absolute;
          background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/date-picker-left-arrow.svg)
            no-repeat;
          top: 5px;
          left: 5px;
          height: 100%;
          width: 100%;
        }
        i {
          display: none;
        }
      }
      .rdrNextButton {
        position: relative;
        background: $primary-light-color;
        &::after {
          content: '';
          position: absolute;
          background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/date-picker-right-arrow.svg)
            no-repeat;
          top: 5px;
          left: 5px;
          height: 100%;
          width: 100%;
        }
        i {
          display: none;
        }
      }
    }

    .rdrMonths {
      .rdrMonth {
        .rdrMonthName {
          display: none;
        }
        .rdrDays {
          .rdrDay {
            color: $secondary-color !important;

            .rdrInRange,
            .rdrStartEdge,
            .rdrEndEdge {
              color: $secondary-color !important;
            }
            .rdrDayNumber {
              span::after {
                background: $secondary-color !important ;
              }
            }
          }
        }
      }
    }
  }
}
.btn-date-picker {
  position: absolute;
  z-index: 1;
  right: 10px;
  bottom: 10px;
}
.custom-dropdown {
  .filter-dropdown {
    margin-right: 0;
  }
  .MuiFormControl-root {
    margin: 10px 0 !important;
    width: 100%;
    .MuiInputLabel-root {
      transform: translate(14px, 16px) scale(1) !important;
      padding-left: 0;
      color: $gray-color !important;
      font-weight: $fweight400 !important;
      &.MuiFormLabel-filled,
      &.Mui-focused {
        transform: translate(14px, -9px) scale(0.75) !important;
      }
    }
    .MuiInputBase-root {
      background: transparent !important;
      .MuiSelect-select {
        border: 1px solid #c4d4ea !important;
        border-radius: $small-border-radius !important;
        padding-left: 15px;
      }
      &:before,
      &:after {
        border: 0 !important;
      }
    }
  }
  &.report-dropdown {
    .MuiFormControl-root {
      // max-width: 160px;
      // margin: auto 0 10px auto !important;
      // display: block;
      // background: $primary-color url(../../assets/images/svg-images/download-white-icon.svg) no-repeat !important;
      // background-position: 92% center !important;
      // border-radius: $small-border-radius;
      max-width: 160px;
      margin: auto 0 10px auto !important;
      display: block;
      .MuiFormLabel-root {
        color: $white-color !important;
      }
      .MuiInputLabel-root {
        transform: none !important;
        padding: 0 12px !important;
        top: 9px !important;
        &.Mui-focused {
          //transform: translate(14px, 9px) scale(1) !important;
          background: transparent !important;
        }
      }
      .MuiInputBase-root {
        width: 100%;
        .MuiOutlinedInput-notchedOutline {
          border: 0 !important;
          background: none !important;
        }
        .MuiSelect-select {
          display: block;
          background: $primary-color url(../../assets/images/svg-images/download-white-icon.svg) no-repeat !important;
          background-position: 92% center !important;
          border-radius: $small-border-radius;
          border: 0 !important;
        }
      }
    }
  }
}

// Flex classes
.flex1 {
  flex: 1;
}
.center {
  @include flex-center;
}

.border-right {
  border-right: 1px solid $border-color;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.auth-main-panel {
  background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/auth-back-img.png)
    no-repeat;
  height: 100vh;
  background-position: center;
  position: relative;
  @include flex-center;
  &:before {
    content: '';
    background: linear-gradient(to right, $primary-alpha-color, $secondary-alpha-color);
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .auth-form-panel {
    background: $auth-form-alpha-color
      url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/auth-back-pattern.png)
      no-repeat;
    // max-width: 700px;
    max-width: 500px;
    width: 100%;
    margin: 0 10px;
    position: relative;
    border-radius: $larger-border-radius 0 $larger-border-radius 0;
    // padding: 100px 95px;
    padding: 100px 50px;
    .logo-block {
      text-align: center;
    }
    .heading-block {
      padding: 60px 0 30px;
      .heading-text {
        font-size: $font20;
        margin-bottom: 0;
      }
    }
    .form-block {
      .form-btn-group {
        padding-top: 50px;
      }
    }
  }
}

.status-pill {
  min-width: 120px;
  max-width: max-content;
  border-radius: $larger-border-radius;
  padding: 5px 10px;
  text-align: center;
  &.completed-pill {
    background: #f1fff3;
    color: #1f902a;
  }
  &.scheduled-pill {
    background: #f4efff;
    color: #5200ff;
  }
  &.not-scheduled-pill {
    background: #fff6ef;
    color: #dc7e3a;
  }
  &.incomplete-pill {
    background-color: #fef2f2 !important;
    color: #dd2121 !important;
  }
  &.not-needed-pill {
    background-color: #f1f1f1 !important;
    color: #848484 !important;
  }
  &.in-progress-pill {
    background-color: #f2f8ff !important;
    color: #0057cd !important;
  }
  &.treatment-planning-pill {
    background-color: #fff3fb !important;
    color: #e55fb8 !important;
  }
  &.imported-pill {
    color: #952cff;
    background-color: #f5ebff !important;
  }
  &.on-treatment-pill {
    color: #ff7e7e;
    background-color: #fff1f1 !important;
  }
  &.treatment-planning-pill {
    color: #e55fbb;
    background-color: #ffeefa !important;
  }
  &.pre-treatment-pill {
    color: #7295c6;
    background-color: #f3f8ff !important;
  }
  &.treatment-completed-pill {
    color: #55b01d;
    background-color: #f5ffee !important;
  }
  &.archived-pill {
    color: #09cab3;
    background-color: #e8fffc !important;
  }
  &.emergency-pill {
    color: #ee3182;
    background-color: $white-color;
  }
  &.routine-checkup-pill {
    color: #00af96;
    background-color: #defefe;
  }
}

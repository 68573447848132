//Search Input Start
.search-panel {
  .MuiInputBase-root {
    position: relative;
    width: 100%;
    &::after,
    &::before {
      content: '';
      border: none !important;
      height: 18px;
      width: 18px;
      background: url('../../assets/images/svg-images/search-icon.svg') center center no-repeat;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      left: auto;
    }
    .MuiInputBase-input {
      border: 1px solid $dark-gray-color;
      padding: 3px 0 3px 10px;
      font-size: 12px !important;
      height: 24px;
    }
  }
}
.search-wrapper {
  .MuiInputBase-input {
    background: url(../../assets/images/svg-images/search-icon.svg) no-repeat;
    background-position: 97%;
  }
}

// --------------------margin------------------

.mb-0 {
  margin: $mr-0;
}

.ml-0 {
  margin-left: $mr-0 !important;
}

.mr-0 {
  margin-right: $mr-0 !important;
}

.mt-0 {
  margin-top: $mr-0;
}

.mb-0 {
  margin-bottom: $mr-0;
}

.mt--3 {
  margin-top: 3px !important;
}

.mb--3 {
  margin-bottom: 3px !important;
}

.mb--5 {
  margin-bottom: $mr-5 !important;
}

.mr--5 {
  margin-right: $mr-5 !important;
}

.ml--5 {
  margin-left: $mr-5;
}

.mt--5 {
  margin-top: $mr-5 !important;
}

.mr-10 {
  margin-right: $mr-10 !important;
}

.ml-10 {
  margin-left: $mr-10 !important;
}

.mt-10 {
  margin-top: $mr-10 !important;
}

.mb-10 {
  margin-bottom: $mr-10 !important;
}

.mb-15 {
  margin-bottom: $mr-15;
}

.ml-15 {
  margin-left: $mr-15;
}

.mt-15 {
  margin-top: $mr-15;
}

.mr-15 {
  margin-right: $mr-15;
}

.mr-20 {
  margin-right: $mr-20 !important;
}

.ml-20 {
  margin-left: $mr-20;
}

.mt-20 {
  margin-top: $mr-20;
}

.mb-20 {
  margin-bottom: $mr-20;
}

.mt-25 {
  margin-top: $mr-25 !important;
}

.mb-25 {
  margin-bottom: $mr-25;
}

.mr-25 {
  margin-right: $mr-25;
}

.ml-25 {
  margin-left: $mr-25;
}

.mr-30 {
  margin-right: $mr-30;
}

.ml-30 {
  margin-left: $mr-30;
}

.mt-30 {
  margin-top: $mr-30;
}

.ml-35 {
  margin-left: 35px;
}

.mb-30 {
  margin-bottom: $mr-30 !important;
}

.mb-35 {
  margin-bottom: $mb-35 !important;
}

.mr-40 {
  margin-right: $mr-40;
}

.ml-40 {
  margin-left: $mr-40;
}

.mt-40 {
  margin-top: $mr-40;
}

.mb-40 {
  margin-bottom: $mr-40 !important;
}

.mt-50 {
  margin-top: $mr-50;
}

.ml-50 {
  margin-left: $mr-50;
}

.mr-50 {
  margin-right: $mr-50;
}

.mb-50 {
  margin-bottom: $mr-50;
}

.ml-60 {
  margin-left: $mr-60;
}

.mr-60 {
  margin-right: $mr-60;
}

.mt-60 {
  margin-top: $mr-60;
}

.mb-60 {
  margin-bottom: $mr-60;
}

// --- Responsive Margin ----

.mb-10-responsive {
  @include lg {
    margin-bottom: 10px;
  }
}

// -------------------paddding---------------

.pd-0 {
  padding: $pd-0 !important;
}

.pl-0 {
  padding-left: $pd-0 !important;
}

.pr-0 {
  padding-right: $pd-0 !important;
}

.pt-0 {
  padding-top: $pd-0 !important;
}

.pb-0 {
  padding-bottom: $pd-0 !important;
}

.pd-5 {
  padding: $pd-5;
}

.pt--5 {
  padding-top: $pd-5;
}

.pb--5 {
  padding-bottom: $pd-5;
}

.pr--5 {
  padding-right: $pd-5;
}

.pl--5 {
  padding-left: $pd-5 !important;
}

.pd-10 {
  padding: $pd-10;
}

.pr-10 {
  padding-right: $pd-10;
}

.pl-10 {
  padding-left: $pd-10;
}

.pt-10 {
  padding-top: $pd-10 !important;
}

.pb-10 {
  padding-bottom: $pd-10 !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pl-100 {
  padding-left: 100px;
}

.pd-15 {
  padding: $pd-15;
}

.pt-15 {
  padding-top: $pd-15;
}

.pl-15 {
  padding-left: $pd-15;
}

.pr-15 {
  padding-right: $pd-15;
}

.pb-15 {
  padding-bottom: $pd-15;
}

.pd-20 {
  padding: $pd-20;
}

.pr-20 {
  padding-right: $pd-20;
}

.pl-20 {
  padding-left: $pd-20;
}

.pt-20 {
  padding-top: $pd-20;
}

.pb-20 {
  padding-bottom: $pd-20 !important;
}

.pd-25 {
  padding: $pd-25;
}

.pt-25 {
  padding-top: $pd-25;
}

.pl-25 {
  padding-left: $pd-25;
}

.pr-25 {
  padding-right: $pd-25;
}

.pb-25 {
  padding-bottom: $pd-25;
}

.pd-30 {
  padding: $pd-30;
}

.pt-30 {
  padding-top: $pd-30;
}

.pr-30 {
  padding-right: $pd-30;
}

.pl-30 {
  padding-left: $pd-30;
}

.pb-30 {
  padding-bottom: $pd-30;
}

.pd-35 {
  padding: $pd-35;
}

.pt-35 {
  padding-top: $pd-35;
}

.pr-35 {
  padding-right: $pd-35;
}

.pl-35 {
  padding-left: $pd-35;
}

.pb-35 {
  padding-bottom: $pd-35;
}

.pd-40 {
  padding: $pd-40;
}

.pr-40 {
  padding-right: $pd-40;
}

.pl-40 {
  padding-left: $pd-40;
}

.pt-40 {
  padding-top: $pd-40;
}

.pb-40 {
  padding-bottom: $pd-40;
}

.pd-50 {
  padding: $pd-50;
}

.pb-50 {
  padding-bottom: $pd-50;
}

.pt-50 {
  padding-top: $pd-50;
}

.pl-50 {
  padding-left: $pd-50;
}

.pr-50 {
  padding-right: $pd-50;
}

.pd-60 {
  padding: $pd-60;
}

.pb-60 {
  padding-bottom: $pd-60;
}

.pt-60 {
  padding-top: $pd-60;
}

.pl-60 {
  padding-left: $pd-60;
}

.pr-60 {
  padding-right: $pd-60;
}
.pr-75 {
  padding-right: $pd-75;
}

.mx-10 {
  margin-top: $mr-10;
  margin-bottom: $mr-10;
}

.mx-20 {
  margin-top: $mr-20;
  margin-bottom: $mr-20;
}

.mx-30 {
  margin-top: $mr-30;
  margin-bottom: $mr-30;
}

.mx-40 {
  margin-top: $mr-40;
  margin-bottom: $mr-40;
}

.my-10 {
  margin-left: $mr-10;
  margin-right: $mr-10;
}

.my-20 {
  margin-left: $mr-20;
  margin-right: $mr-20;
}

.my-30 {
  margin-left: $mr-30;
  margin-right: $mr-30;
}

.my-40 {
  margin-left: $mr-40;
  margin-right: $mr-40;
}

.px-10 {
  padding-top: $pd-10;
  padding-bottom: $pd-10;
}

.px-20 {
  padding-top: $pd-20;
  padding-bottom: $pd-20;
}

.px-30 {
  padding-top: $pd-30;
  padding-bottom: $pd-30;
}

.px-40 {
  padding-top: $pd-40;
  padding-bottom: $pd-40;
}

.py-10 {
  padding-left: $pd-10;
  padding-right: $pd-10;
}

.py-20 {
  padding-left: $pd-20;
  padding-right: $pd-20;
}

.py-30 {
  padding-left: $pd-30;
  padding-right: $pd-30;
}

.py-40 {
  padding-left: $pd-40;
  padding-right: $pd-40;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  z-index: 9;
  padding: 0 10px 10px;
  transition: $transition;
  // @include md {
  //     top: 6px;
  //     z-index: -9;
  // }
  &.active-sidebar-panel {
    width: 190px;
    background: $primary-color
      url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/sidebar-pattern-img.png)
      no-repeat;
    padding: 0;
    @include md {
      width: 80px;
      z-index: 9;
    }
    .sidebar-logo-panel {
      padding: 10px 0;
      @include md {
        background: transparent;
      }
      .logo-btn {
        width: 130px;
        margin: 0 auto;
        &:before {
          background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/app-white-logo.png)
            no-repeat;
        }
        @include md {
          width: 40px;
        }
      }
    }
    .navbar-panel {
      width: 190px;
      border-radius: 0;
      background: transparent;
      @include md {
        width: 80px;
      }
      .sidebar-nav-items {
        .nav-item {
          .nav-item-text {
            display: block;
            @include md {
              display: none;
            }
          }
          &.dropdown-item {
            &:after {
              @include md {
                right: 5px;
              }
            }
            &.open-submenu {
              height: 200px;
              .submenu-nav-item .submenu-nav-text {
                margin: 0;
                justify-content: flex-start;
                @include md {
                  margin: 0 auto;
                  justify-content: center;
                }
              }
            }
          }
          .nav-icon-block {
            margin: 0 10px;
          }
          .nav-submenu .submenu-nav-item.open-preTreatment-menu .pre-treatment-menu {
            padding-left: 28px;
            @include md {
              padding-left: 0;
            }
          }
        }
      }
    }
    .login-user-panel {
      background: $primary-light-color;
      margin: 0 10px;
      &:after {
        @include md {
          opacity: 1;
          // background: url(../../assets/images/svg-images/white-left-arrow.svg)
          // no-repeat !important;
          right: -8px;
        }
      }

      .user-name-block {
        width: auto;
        opacity: 1;
        @include md {
          display: none;
        }
      }
      @include md {
        width: auto;
        background: transparent;
        .user-img-block {
          opacity: 1;
        }
      }
      .nav-profile-menu {
        left: 190px;
        @include md {
          left: 80px;
        }
      }
    }
  }
  .sidebar-logo-panel {
    cursor: pointer;
    padding: 10px 0;
    @include md {
      background: $white-color;
      padding: 4px 0;
    }
    .logo-btn {
      background: transparent;
      border: 0;
      padding: 0;
      position: relative;
      width: 40px;
      height: 40px;
      transition: $transition;
      margin-left: 20px;
      @include md {
        margin-left: 0;
      }
      &:before {
        content: '';
        position: absolute;
        background: url(../../assets/images/app-small-logo.png) no-repeat;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      .app-active-logo {
        max-width: 90%;
        margin: 0 auto;
      }
    }
  }
  .navbar-panel {
    background: $primary-color;
    border-radius: $larger-border-radius;
    padding: 50px 0;
    width: 80px;
    // margin: 20px 0;
    transition: $transition;
    height: calc(100% - 130px);
    @include md {
      width: 0;
    }
    .sidebar-nav-items {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .nav-item {
        transition: $transition;
        margin-bottom: 18px;
        padding: 10px 0 0;
        a {
          display: flex;
          align-items: center;
          @include md {
            justify-content: center;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.dropdown-item {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 5px;
            height: 12px;
            background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/dropdown-right-arrow.svg)
              no-repeat;
            right: 10px;
            top: 24px;
            transition: $transition;
          }
          &.open-submenu {
            background: rgba(242, 248, 255, 0.1);
            &:after {
              width: 10px;
              height: 10px;
              right: 3px;
              background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/submenu-down-arrow.svg)
                no-repeat;
            }
            .nav-submenu {
              display: block;
            }
            .submenu-nav-item {
              .submenu-nav-text {
                opacity: 1;
                margin: 0 auto;
                justify-content: center;
                transition: $transition;
              }
            }
            .nav-icon-block {
              &:after {
                width: 100%;
              }
              &.configuration-icon-block {
                &:before {
                  background: url(../../assets/images/svg-images/configuration-nav-active-icon.svg) no-repeat;
                }
              }
            }
          }
          button {
            border: 0;
            background: transparent;
            display: flex;
            align-items: center;
            padding: 0;
            @include md {
              margin: 0 auto;
            }
          }
        }

        .nav-item-text {
          color: $white-color;
          display: none;
          transition: $transition;
          font-weight: 500;
        }
        &:hover,
        &:focus,
        .active {
          .nav-icon-block {
            background: $white-color;
            &.dashboard-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/dashboard-nav-active-icon.svg) no-repeat;
              }
            }
            &.worklist-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/worklist-nav-active-icon.svg) no-repeat;
              }
            }
            &.patients-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/patients-nav-active-icon.svg) no-repeat;
              }
            }
            &.pretreatment-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/preTreatment-nav-active-icon.svg) no-repeat;
              }
            }
            &.treatmentplanning-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/treatment-planning-nav-active-icon.svg) no-repeat;
              }
            }
            &.ontreatment-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/on-treatment-nav-active-icon.svg) no-repeat;
              }
            }
            &.endtreatment-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/end-treatment-nav-active-icon.svg) no-repeat;
              }
            }
            &.nursing-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/nursing-nav-active-icon.svg) no-repeat;
              }
            }
            &.priorauthorization-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/priorAuthorization-nav-active-icon.svg) no-repeat;
              }
            }
            &.configuration-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/configuration-nav-active-icon.svg) no-repeat;
              }
            }
            &.chart-icon-block {
              &:before {
                background: url(../../assets/images/svg-images/chart-nav-active-icon.svg) no-repeat;
              }
            }
            &:after {
              width: 100%;
            }
          }
        }
        .nav-submenu {
          padding: 10px 0;
          text-align: left;
          list-style: none;
          transition: 500ms ease-in-out;
          overflow: hidden;
          display: none;
          @include md {
            left: -30px;
          }
          .submenu-nav-item {
            transition: $transition;
            position: relative;
            margin: 2px 0;
            transition: $transition;
            padding: 5px 0;
            &:hover {
              background: $primary-color;
            }
            &.open-preTreatment-menu {
              background: $primary-color;
              .pre-treatment-menu {
                display: block;
              }
            }
            .submenu-nav-text {
              color: $primary-color;
              display: flex;
              overflow: hidden;
              transition: $transition;
              opacity: 0;
              .nav-item-text {
                color: $white-color;
                word-break: break-word;
                white-space: initial;
                text-align: left;
              }
            }
            .nav-icon-block {
              background: transparent;
              margin: 0;
              &:after {
                content: none;
              }
            }
            .pre-treatment-menu {
              list-style: none;
              padding-left: 0;
              display: none;
            }
          }
        }
      }
    }
  }
  .login-user-panel {
    background: transparent;
    border-radius: $small-border-radius;
    padding: 6px 10px;
    transition: $transition;
    margin-top: 5px;
    @include flex-center;
    @include md {
      width: 0;
    }
    position: relative;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 25px;
      background: url(../../assets/images/svg-images/profile-open-angle-con.svg) no-repeat;
      width: 10px;
      height: 10px;

      @include md {
        opacity: 0;

        // background: url(backgroundsvg-images/white-left-arrow.svg)
        //     no-repeat !important;
        right: -8px;
      }
    }
    .user-img-block {
      width: 45px;
      height: 45px;
      border-radius: $rounded-border-radius;
      transition: $transition;
      @include md {
        opacity: 0;
      }
    }
    .user-name-block {
      width: 0;
      overflow: hidden;
      transition: $transition;
      opacity: 0;
      .user-name {
        font-size: $font16;
        font-weight: $fweight600;
        padding-left: 15px;
      }
    }
    &.open-profile-menu {
      .nav-profile-menu {
        width: 200px;
      }
    }
    .nav-profile-menu {
      list-style: none;
      position: absolute;
      left: 90px;
      bottom: 0;
      width: 0;
      background: $primary-light-color;
      padding: 0 0 5px;
      overflow: hidden;
      border-radius: 0 $large-border-radius $large-border-radius 0;
      transition: $transition;
      margin-bottom: 0;
      .profile-details {
        background: $primary-color;
        color: $white-color;
        padding: 8px 12px;

        span {
          display: block;
          white-space: nowrap;
        }
        .profile-img {
          width: 24px;
          height: 24px;
          object-fit: cover;
          border-radius: $rounded-border-radius;
          margin-right: 6px;
        }
      }
      .submenu-nav-item {
        padding: 8px 15px;
        transition: $transition;
        position: relative;
        &:hover {
          background: $white-color;
          &:before {
            left: 0;
          }
          .submenu-nav-text {
            color: $primary-color;
            .nav-icon-block {
              &.myProfile-block {
                &:before {
                  background: url(../../assets/images/svg-images/my-profile-nav-hover-icon.svg) no-repeat;
                }
              }
              &.password-block {
                &:before {
                  background: url(../../assets/images/svg-images/change-pswd-nav-hover-icon.svg) no-repeat;
                }
              }
              &.logout-block {
                &:before {
                  background: url(../../assets/images/svg-images/logout-nav-hover-icon.svg) no-repeat;
                }
              }
            }
          }
        }
        &:before {
          content: '';
          position: absolute;
          left: -5px;
          top: 0;
          width: 10px;
          height: 100%;
          border-right: 5px solid $primary-light-color;
          background: $white-color;
          opacity: 1;
          transition: $transition;
        }

        .submenu-nav-text {
          color: $gray-color;
          display: flex;
          transition: $transition;
          .nav-item-text {
            white-space: nowrap;
          }
          .nav-icon-block {
            min-width: 16px;
            height: 16px;
            margin: 0 10px 0 0;
            border-radius: 0;
            &:after {
              content: none;
            }
            &:before {
              width: 16px;
              height: 16px;
              background-size: 15px !important;
            }
            &.myProfile-block {
              &:before {
                background: url(../../assets/images/svg-images/my-profile-nav-icon.svg) no-repeat;
              }
            }
            &.password-block {
              &:before {
                background: url(../../assets/images/svg-images/change-pswd-nav-icon.svg) no-repeat;
              }
            }
            &.logout-block {
              &:before {
                background: url(../../assets/images/svg-images/logout-nav-icon.svg) no-repeat;
              }
            }
          }
        }
      }
    }
  }
  .nav-icon-block {
    background: rgba(255, 255, 255, 0.05);
    display: block;
    min-width: 40px;
    height: 40px;
    border-radius: $small-border-radius;
    @include flex-center;
    transition: $transition;
    position: relative;
    overflow: hidden;
    margin: 0 20px;
    .nav-active-icon {
      display: none;
    }
    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background-position: center !important;
      transition: $transition;
      z-index: 9;
    }
    &.dashboard-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/dashboard-nav-icon.svg) no-repeat;
      }
    }
    &.worklist-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/worklist-nav-icon.svg) no-repeat;
      }
    }
    &.patients-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/patients-nav-icon.svg) no-repeat;
      }
    }
    &.pretreatment-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/preTreatment-nav-icon.svg) no-repeat;
      }
    }
    &.treatmentplanning-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/treatment-planning-nav-icon.svg) no-repeat;
      }
    }
    &.ontreatment-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/on-treatment-nav-icon.svg) no-repeat;
      }
    }
    &.endtreatment-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/end-treatment-nav-icon.svg) no-repeat;
      }
    }
    &.nursing-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/nursing-nav-icon.svg) no-repeat;
      }
    }
    &.priorauthorization-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/priorAuthorization-nav-icon.svg) no-repeat;
      }
    }
    &.configuration-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/configuration-nav-icon.svg) no-repeat;
      }
    }
    &.chart-icon-block {
      &:before {
        background: url(../../assets/images/svg-images/chart-nav-icon.svg) no-repeat;
      }
    }
    &.clinical-info-sub-block {
      &:before {
        // background: url(../../assets/images/svg-images/clinicalInfo-sub-icon.svg)
        //     no-repeat;
      }
    }
    &.sim-order-sub-block {
      &:before {
        // background: url(../../assets/images/svg-images/sim-order-sub-icon.svg)
        //     no-repeat;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      background: $white-color;
      left: 0;
      top: 0;
      transition: $transition;
    }
  }
}

.btn {
  min-width: 120px;
  border-radius: $small-border-radius !important;
  height: 30px;
  padding: 0 5px !important;
  font-size: $font12 !important;
  line-height: $lheight28 !important;
  box-shadow: none !important;
  color: $white-color !important;
  position: relative;
  border: none;

  &.full-width-btn {
    max-width: 100%;
  }
  .app-icon {
    display: inline-block;
  }
  .app-hover-icon {
    display: none;
  }
  &:hover {
    .app-hover-icon {
      display: inline-block;
    }
    .app-icon {
      display: none;
    }
  }
}
.btn-primary {
  background: $primary-color !important;
  border-color: $primary-color !important;
  border: 1px solid $primary-color !important;
  transition: 300ms ease-in-out;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    background: $white-color;
    left: 0;
    top: 0;
    transition: 300ms ease-in-out;
    z-index: -1;
  }
  &:hover {
    background: $white-color !important;
    color: $primary-color !important;
    transition: 300ms ease-in-out;
    &::after {
      width: 100%;
    }
  }
}
.btn-secondary {
  background: $secondary-color !important;
  border-color: $secondary-color !important;
  border: 1px solid $secondary-color !important;
  transition: 300ms ease-in-out;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    background: $white-color;
    left: 0;
    top: 0;
    transition: 300ms ease-in-out;
    z-index: -1;
  }
  &:hover {
    background: $white-color !important;
    color: $secondary-color !important;
    transition: 300ms ease-in-out;
    &::after {
      width: 100%;
    }
  }
}
.btn-tertary {
  border-radius: 20px;
  font-size: $font16;
  line-height: $lheight20;
  min-width: 170px;
  height: 40px;
  font-weight: $fweight600;
  padding: 0 5px !important;
  background: $white-color !important;
  border: 1px solid $white-color !important;
  color: $secondary-dark-color !important;
  transition: 300ms ease-in-out;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    background: $secondary-dark-color;
    left: 0;
    top: 0;
    transition: 300ms ease-in-out;
    z-index: -1;
  }
  &:hover {
    color: $white-color !important;
    transition: 300ms ease-in-out;
    &::after {
      width: 100%;
    }
  }
}
.large-btn {
  min-width: 240px;
}
.calendar {
  min-width: 32px;
}
.small-btn-primary {
  height: 28px;
  max-width: 28px;
  min-width: 28px;
  line-height: $lheight20 !important;
  img {
    max-width: 18px;
  }
}
.smaller-btn-primary {
  height: 20px;
  max-width: 20px;
  min-width: 20px;
  @include flex-center;
}

//font variables
$font-stack: 'Poppins', sans-serif;

//transition
$transition: 300ms ease-in-out;

//font size variables
$font50: 50px;
$font40: 40px;
$font30: 30px;
$font25: 25px;
$font22: 22px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;

//line height variables
$lheight54: 54px;
$lheight44: 44px;
$lheight34: 34px;
$lheight30: 30px;
$lheight28: 28px;
$lheight26: 26px;
$lheight24: 24px;
$lheight22: 22px;
$lheight20: 20px;
$lheight18: 18px;
$lheight16: 16px;
$lheight14: 14px;
$lheight12: 12px;
$lheight10: 10px;

//font weight variables
$fweight300: 300;
$fweight400: 400;
$fweight500: 500;
$fweight600: 600;
$fweight700: 700;
$fweight900: 900;

//border-radius
$larger-border-radius: 50px;
$large-border-radius: 10px;
$small-border-radius: 6px;
$smaller-border-radius: 3px;
$rounded-border-radius: 50%;

//media query breakpoints
$screen-desktop: 1920px;
$screen-laptop-max: 1680px;
$screen-laptop: 1440px;
$screen-xl-max: 1200px;
$screen-xl-min: 1199px;
$screen-tab: 1024px;
$screen-lg-min: 992px;
$screen-lg-max: 991px;
$screen-md-max: 767px;
$screen-sm-max: 575px;
$screen-sm-min: 480px;
$screen-mobile-max: 375px;

//common -color variables
$primary-color: #1b365b;
$primary-alpha-color: rgba(0, 44, 105, 0.52);
$secondary-color: #77aca2;
$secondary-dark-color: #3bb3a9;
$secondary-light-color: #f1f8f4;
$secondary-alpha-color: rgba(119, 172, 162, 0.52);
$auth-form-alpha-color: rgba(232, 237, 244, 0.7);
$gray-color: #6f85a3;
$white-color: #ffffff;
$black-color: #000000;
$border-color: #e5f3fa;
$dark-gray-color: #c4d4ea;
$background-color: #f1f8f4;
$primary-light-color: #f2f8ff;
$border-green-color: #bee0cd;

// Padding
$pd-0: 0px !default;
$pd-5: 5px !default;
$pd-10: 10px !default;
$pd-15: 15px !default;
$pd-20: 20px !default;
$pd-25: 25px !default;
$pd-30: 30px !default;
$pd-35: 35px !default;
$pd-40: 40px !default;
$pd-50: 50px !default;
$pd-60: 60px !default;
$pd-75: 75px !default;

// Margin
$mr-0: 0px !default;
$mr-5: 5px !default;
$mr-10: 10px !default;
$mr-15: 15px !default;
$mr-20: 20px !default;
$mr-25: 25px !default;
$mr-30: 30px !default;
$mb-35: 35px !default;
$mr-40: 40px !default;
$mr-50: 50px !default;
$mr-60: 60px !default;

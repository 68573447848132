// Radio scss
.MuiFormGroup-root {
  .MuiFormControlLabel-root {
    .MuiFormControlLabel-label {
      font-size: $font12 !important;
      font-family: 'Poppins', sans-serif;
    }
  }
  .MuiButtonBase-root {
    color: $dark-gray-color !important;
    &.Mui-checked {
      color: $primary-color !important;
      & ~ span {
        color: $primary-color;
      }
    }
  }
}
// checkbox and radio buttons
.MuiFormGroup-root {
  &.MuiFormGroup-row {
    flex-wrap: nowrap !important;
  }
  .MuiButtonBase-root {
    color: $dark-gray-color;
    border-radius: $smaller-border-radius;
    padding-right: $pd-15;
    &.MuiCheckbox-root {
      &.Mui-checked {
        color: $primary-color !important;
        .MuiSvgIcon-root {
          border: none;
          height: 18px;
          width: 18px;
          border-radius: $smaller-border-radius;
          background: $primary-color
            url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/checked-white-icon.svg)
            no-repeat;
          background-position: center;
          background-size: 14px;
        }
        &.MuiTypography-root {
          color: $primary-color !important;
        }
        &.MuiRadio-root {
          .MuiSvgIcon-root {
            //border: 1px solid $dark-gray-color;
            border-radius: $rounded-border-radius;
            height: 24px;
            width: 24px;
          }
        }
      }
      .MuiSvgIcon-root {
        height: 18px;
        width: 18px;
        border: 1px solid $dark-gray-color;
        border-radius: $smaller-border-radius;
        path {
          display: none;
        }
      }
    }
    &:hover {
      background-color: transparent !important;
    }
    &.MuiRadio-root {
      .MuiSvgIcon-root {
        //border: 1px solid $dark-gray-color;
        border-radius: 50%;
        height: 24px;
        width: 24px;
      }
    }
  }
  .MuiTypography-root {
    color: $gray-color;
    line-height: $lheight20;
    font-size: $font14;
  }
}

.MuiPaper-root {
  .MuiList-root {
    .select-all-wrap {
      .MuiButtonBase-root {
        color: #77aca2 !important;
      }
    }
  }
}

.select-all-wrap {
  margin-left: 7px;
  margin-bottom: 7px;
  cursor: pointer;
  &:hover {
    background-color: #f2f8ff;
    border-radius: 3px;
    color: #1b365b !important;
  }
  .MuiListItemText-root {
    .MuiTypography-root {
      font-size: 12px !important;
      color: #6f85a3 !important;
    }
  }
}

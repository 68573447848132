.user-profile-panel {
  .app-card {
    &.open-edit-panel {
      .profile-left-block {
        .profile-img-block {
          .upload-img-icon {
            // display: block;
            width: auto;
            height: auto;
            border-radius: 0;
            margin: auto;
          }
          .uploaded-img {
            .delete-icon {
              opacity: 1;
            }
          }
          &.delete-img {
            .uploaded-img {
              display: none;
            }
            .upload-img-icon {
              display: block;
            }
          }
        }
      }
      .profile-right-block {
        .profile-input-panel {
          display: block;
        }
        .profile-text-panel {
          display: none;
        }
      }
    }
    .profile-left-block {
      text-align: center;
      @include md {
        margin-bottom: 50px;
      }
      .profile-img-block {
        border: 1px solid $border-green-color;
        padding: 8px;
        border-radius: $large-border-radius;
        max-width: 120px;
        height: 140px;
        margin: 0 auto;
        .uploaded-profile-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $large-border-radius;
        }
        .upload-img-icon {
          display: none;
        }
        .uploaded-img {
          position: relative;
          height: 100%;
          .delete-icon {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
            opacity: 0;
          }
        }
        .upload-box {
          position: relative;
          height: 100%;
          display: flex;
          .file-upload-input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }
    }
    .profile-right-block {
      @include md {
        margin-left: 0;
      }
      .profile-text-panel,
      .profile-input-panel {
        margin-bottom: 30px;
      }
      .profile-input-panel {
        display: none;
      }
    }
  }
  .btn-wrapper {
    .btn-primary {
      margin: 0 15px;
    }
  }
}

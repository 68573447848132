.sim-main-wrapper,
.work-list-main-wrapper,
.header-panel-main-wrapper,
.on-treatment-wrapper {
  // overflow: hidden;
  padding: 0 3px;
  position: relative;
  &.open-sidebar-panel {
    .simulation-history-wrapper {
      transform: translate(0);
      @include sm {
        max-width: 100%;
      }
    }
  }
}
.simulation-layout-margin {
  margin-top: 30px;
  padding-right: 10px;
  @include lg {
    margin-top: 20px;
  }
}
.simulation-view-wrapper {
  max-width: 100%;
  transition: $transition;
  .simulation-view-content {
    height: 100%;
    .app-card {
      height: calc(100% - 20px);
      .app-card-body {
        .clinical-stage-view-content {
          border-right: 1px solid $border-color;
          height: calc(100% - 30px);
          @include laptop-max {
            padding-right: 10px;
          }
          @include xl {
            border-right: none;
            padding-right: 0;
          }
        }
      }
    }
    .simulation-text-panel {
      .text-label {
        color: $gray-color;
        line-height: $lheight14;
        font-size: $font12;
        font-weight: $fweight500;
        padding-bottom: $pd-5;
      }
    }
  }
}

.MuiPaper-root {
  .MuiList-root {
    .select-all-wrap {
      .MuiButtonBase-root {
        color: #77aca2 !important;
      }
    }
  }
}

.select-all-wrap {
  margin-left: 7px;
  margin-bottom: 7px;
  cursor: pointer;
  &:hover {
    background-color: #f2f8ff;
    border-radius: 3px;
    color: #1b365b !important;
  }
  .MuiListItemText-root {
    .MuiTypography-root {
      font-size: 12px !important;
      color: #6f85a3 !important;
    }
  }
}
.simulation-history-wrapper {
  max-width: 400px;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transition: $transition;
  transform: translate(410px);
  background: $white-color;
  z-index: 999;
  height: 100%;
  @include box-shadow;

  @include md {
    max-width: 300px;
  }

  .history-close-wrapper {
    padding: 20px 10px;
    cursor: pointer;
  }
  .history-heading-wrapper {
    background: $primary-color;
    padding: 8px 20px;
    z-index: 9;
    position: relative;
  }
  .history-body-wrapper {
    max-height: 750px;
    overflow: auto;
    .history-list {
      .history-block {
        border-top: 1px solid $border-color;
        &:first-child {
          border-top: 0;
        }
        .history-day-block {
          padding: 20px;
        }
        .history-details-block {
          padding: 20px;
          border-top: 1px solid $border-color;
          &.current-block {
            background: $primary-light-color;
          }
          .history-user-block {
            .history-username {
              position: relative;
              padding-left: 15px;
              &:before {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background: $secondary-color;
                border-radius: $rounded-border-radius;
              }
            }
          }
        }
      }
    }
  }
}

// Chart module table
.chart-table {
  .archive-patient-row {
    background-color: rgba($color: #f1f8f4, $alpha: 0.4);
  }
  .cell-patient {
    width: 130px;
    &.archive-patient {
      span {
        color: $secondary-color !important;
      }
    }
    .reviewed-icon {
      margin-left: -20px;
      height: 18px;
      img {
        height: 16px;
      }
    }
    // .reviewed-icon {
    //     position: relative;
    //     width: 11px;
    //     &::before {
    //         content: "";
    //         position: absolute;
    //         background: url(../../assets/images/svg-images/check-circle.svg) no-repeat;
    //         background-size: 100% 100%;
    //         height: 24px;
    //         width: 16px;
    //         left: -18px;
    //         top: -2px;
    //     }
    // }
  }
  .cell-cancer-site {
    width: 150px;
  }
  .cell-treatment-team {
    width: 280px;
  }
  .cell-staging {
    width: 235px;
  }

  .cell-brief-plan {
    width: 250px;
  }

  .cell-prescription {
    width: 135px;
  }

  .cell-organ-at-risk {
    width: 145px;
    .organs-name {
      background-color: $background-color;
      padding: 5px 15px;
      position: relative;
      width: 110px;
      border-radius: $small-border-radius;
      &::after {
        content: '';
        position: absolute;
        background: url(../../assets/images/svg-images/blue-down-arrow.svg) no-repeat;
        background-size: 100% 100%;
        height: 22px;
        width: 11px;
        right: 5px;
        top: 3px;
      }
    }
    .treatment-goals-table-view {
      &.chart-table-view {
        @include lg {
          overflow: hidden !important;
        }
        .header-wrapper {
          @include lg {
            width: auto;
          }
          .header-heading-text {
            width: 28% !important;
            &.priority-text {
              width: 15% !important;
            }
            @include lg {
              min-width: auto;
            }
          }
        }
        .body-wrapper {
          @include lg {
            width: auto;
          }
          .body-wrapper-text {
            width: 28% !important;
            &.priority-text {
              width: 15% !important;
            }
            @include lg {
              min-width: auto;
            }
          }
        }
      }
    }
  }
  .cell-start-date {
    width: 115px;
  }
  .cell-notes {
    width: 240px;
    .notes-icon {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        background: url(../../assets/images/svg-images/save-hover-icon.svg) no-repeat;
        background-size: 100% 100%;
        height: 18px;
        width: 16px;
        right: 5px;
        top: 7px;
      }
    }
  }
  .cell-action {
    width: 70px;
  }
  &.open-expansion {
    // .cell-organ-at-risk {
    //     // position: relative;
    //     width: 350px;
    //     .organs-name {
    //         width: 500px;
    //         // position: absolute;
    //         background-color: $background-color;
    //         padding: 5px 10px;
    //         border-radius: $small-border-radius;
    //     }
    // }
    .cell-organ-at-risk {
      position: relative;

      .organs-name-content {
        .organs-name {
          &::after {
            content: '';
            position: absolute;
            background: url(../../assets/images/svg-images/blue-upper-arrow.svg) no-repeat;
            right: 7px;
            top: 11px;
          }
        }
        .details-wrapper {
          display: block !important;
          position: absolute;
          top: 35px;
          left: 0;
          width: 532px;
          background-color: $background-color;
          z-index: 99;
          border-radius: $small-border-radius;

          @include box-shadow;
          .chart-table-view {
            padding: 0 10px;

            .body-wrapper {
              padding: 5px 10px;
              background: transparent;
              .body-wrapper-text {
                &:last-child {
                  min-width: 60px;
                }
              }
            }
            .header-wrapper {
              padding: 0 5px;
              .header-heading-text {
                &:last-child {
                  min-width: 66px;
                }
              }
            }
          }
        }
      }
    }
  }
  .total-fraction,
  .total-dose {
    background: $background-color;
    padding: 5px 10px;
    border-radius: $small-border-radius;
    margin-right: 10px;
    width: fit-content;
  }
}

.chart-table-panel-content {
  .MuiBox-root {
    padding: 0 !important;
  }
}
.all-organs-popup {
  .cell-organs {
    width: 150px;
  }
  .cell-structure {
    width: 160px;
  }
  .cell-primary {
    width: 190px;
  }
  .cell-acceptable {
    width: 250px;
  }
  .cell-priority {
    width: 70px;
  }
  .data-body-row {
    span {
      color: $secondary-color !important;
    }
  }
  .data-body-row,
  .data-header-row {
    border-bottom: 1px solid $background-color;
    padding: 10px 0;
  }
}

.patient-list {
  .app-card {
    height: calc(100vh - 140px);
    overflow-y: auto;
    @include lg {
      height: auto;
      overflow: hidden;
    }
  }
  .patient-list-wrapper {
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 20px 10px;
    .list {
      height: calc(100vh - 220px);
      overflow-x: hidden;
      overflow-y: auto;
      @include lg {
        height: 100%;
        max-height: 330px;
      }
      .list-wrapper {
        padding: 5px 0 5px;
        border-bottom: 1px solid $border-color;
        .list-row {
          border-radius: 10px;
          padding: 7px 15px;
          transition: $transition;
          .profile {
            height: 60px;
            width: 60px;
            padding: 5px;
            border-radius: $rounded-border-radius;
            border: 1px solid $secondary-color;
            overflow: hidden;
            margin: 0 10px;
            img {
              height: 100%;
              width: 100%;
              border-radius: $rounded-border-radius;
            }
          }
          &:hover,
          &.active {
            background-color: $secondary-light-color;
            .text-gray {
              color: $primary-color;
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.patient-details {
  height: calc(100vh - 120px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  @include lg {
    height: auto;
  }
  .patient-details-wrapper {
    .patient-personal-details {
      .profile {
        height: 130px;
        padding: 5px;
        border-radius: 10px;
        border: 1px solid $secondary-color;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .details-pill-wrapper {
        border-right: 1px solid $border-color;
        @include lg {
          border-right: 0;
          margin-bottom: 20px;
          .mr-20 {
            margin: 0 auto 10px !important;
          }
        }
        .details-pill {
          padding: 8px;
          border-radius: 20px;
          background-color: $secondary-light-color;
          text-align: center;
          margin-bottom: 20px;
          @include lg {
            margin-bottom: 10px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .appointment-details {
      .appointment-card {
        background-color: $primary-light-color;
        padding: 20px;
        border-radius: $small-border-radius;
        margin-bottom: 20px;
      }
    }
    .date-text {
      padding-left: 20px;
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/calendar-gray-icon.svg)
        no-repeat;
      background-size: 14px;
      background-position: left center;
    }
    .treatment-status-details,
    .appointment-details {
      .status-pill {
        min-width: 80px;
        padding: 2px 5px;
        span {
          font-size: 10px !important;
        }
      }
      .sim-status-block {
        background: $primary-light-color;
        padding: 5px;
        border-radius: $smaller-border-radius;
      }
      .block-title {
        width: 150px;
      }
      .progress-block {
        background: $primary-light-color;
        min-width: 50px;
        height: 50px;
        width: 50px;
        border-radius: $rounded-border-radius;
        @include flex-center;
        @include laptop {
          min-width: 80px;
          height: 80px;
        }
        &.fraction-progress-block {
          .MuiCircularProgress-root {
            &:before {
              content: '8';
            }
          }
        }
      }
      // .MuiCircularProgress-root {
      //   background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/on-treatment-progress-icon.svg) no-repeat;
      // }
      .MuiCircularProgress-root {
        width: 40px !important;
        height: 40px !important;
        transform: none !important;
        background-size: 40px !important;
        background-position: center !important;
        color: $primary-color;
        position: relative;
        font-size: $font14;
        font-weight: $fweight600;
        &:before {
          bottom: 0;
          color: #1b365b;
          content: '1600';
          font-weight: 500;
          left: 0;
          margin: auto;
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $font12;
        }
        @include laptop {
          width: 70px !important;
          height: 70px !important;
          background-size: 50px !important;
        }
        .MuiCircularProgress-svg {
          transform: rotate(-90deg);
          .MuiCircularProgress-circle {
            stroke-linecap: round;
          }
        }
      }
    }
    .nursing-tasks-details {
      &.app-card {
        height: calc(100% - 20px);
      }
    }
  }
}

.patient-table {
  .cell-patient {
    width: 230px;
  }
  .cell-address {
    width: 380px;
  }
  .cell-phone {
    width: 250px;
  }
  .cell-physician {
    width: 250px;
  }
  .cell-first-consult-date {
    width: 250px;
  }
  .cell-status {
    width: 250px;
  }
  .cell-action {
    width: 80px;
  }
}

.patient-search-entry-wrapper {
  padding-bottom: 10px;
  .patient-record-wrapper {
    .patient-record-content {
      .patient-mrn-details {
        padding: 30px;
        @include laptop {
          padding: 10px;
        }
        .mrn-field,
        .patient-name {
          max-width: 190px;
          padding: 5px 10px;
        }
      }
      .patient-details-wrapper {
        border-left: 1px solid $border-color;
        height: 100%;
        padding: 20px;
        @include sm {
          border-top: 1px solid $border-color;
          border-left: none;
        }
        .patient-details-content {
          .address-panel {
            @include xl-min {
              margin-top: 20px;
            }
          }
        }
      }
      .select-patient-wrapper {
        background-color: $primary-color;
        border-radius: 0 10px 10px 0;
        padding: 25px;
        display: block;
        border: 0;
        @include laptop {
          padding: 10px;
        }
        @include xl-min {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }
}

.header-panel {
  position: fixed;
  top: 0;
  right: 20px;
  width: calc(100% - 120px);
  padding: 8px 10px;
  border-radius: 0 0 $large-border-radius $large-border-radius;
  transition: $transition;
  background: $white-color;
  z-index: 9;
  @include box-shadow;
  @include md {
    right: 5px;
    width: calc(100% - 65px);
  }
  .toggle-panel {
    cursor: pointer;
    .toggle-icon {
      width: 25px;
    }
  }
  .header-inner-panel {
    background: $secondary-light-color;
    padding: 5px 20px;
    border-radius: $large-border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    @include md {
      padding: 5px;
    }
    .header-left-panel {
      .breadcrumb-panel {
        .breadcrumb-item {
          // padding: 0 20px;
          @include lg {
            display: none !important;
          }
          &:first-child {
            padding-left: 0;
          }
          &:before {
            content: none;
          }
          &.active {
            // padding: 0 20px 0 30px;
            position: relative;
            @include lg {
              display: flex !important;
            }
            @include sm-min {
              padding: 0 0 0 20px;
            }

            // &:before {
            //     content: "";
            //     position: absolute;
            //     width: 24px;
            //     height: 24px;
            //     left: 5px;
            //     top: 0;
            //     background-size: 20px !important;
            //     @include sm-min {
            //         left: 1px;
            //         top: -11px;
            //     }
            // }
            .breadcrumb-text {
              color: $primary-color;
              position: relative;
              padding-left: 30px;
              &:before {
                content: '';
                position: absolute;
                width: 24px;
                height: 24px;
                left: 0;
                top: 0;
                background-size: 20px !important;
                @include sm-min {
                  left: 1px;
                  top: -11px;
                }
              }
            }
          }
          &.dashboard-item {
            &:before {
              background: url(../../assets//images/svg-images/dashboard-nav-active-icon.svg) no-repeat;
            }
          }
          &.patient-tracker-item {
            &:before {
              // background: url(../../assets//images/svg-images/patient-tracker-item.svg)
              //     no-repeat;
            }
          }
          &.Patient-item {
            &:before {
              background: url(../../assets//images/svg-images/patients-nav-active-icon.svg) no-repeat;
            }
          }

          &.pre-treatment-item {
            .breadcrumb-text {
              &:before {
                background: url(../../assets/images/svg-images/preTreatment-nav-active-icon.svg) no-repeat;
              }
            }
          }
          &.treatment-planning-item {
            .breadcrumb-text {
              &:before {
                background: url(../../assets//images/svg-images/treatment-planning-nav-active-icon.svg) no-repeat;
              }
            }
          }
          &.on-treatment-item {
            .breadcrumb-text {
              &:before {
                background: url(../../assets//images/svg-images/on-treatment-nav-active-icon.svg) no-repeat;
              }
            }
          }
          &.Configuration-item {
            &:before {
              background: url(../../assets//images/svg-images/configuration-nav-active-icon.svg) no-repeat;
            }
          }
          &.Dashboard-item {
            &:before {
              background: url(../../assets//images/svg-images/dashboard-nav-active-icon.svg) no-repeat;
            }
          }
          &.Simulation-item {
            &:before {
              // background: url(../../assets//images/svg-images/sim-nav-icon.svg)
              //     no-repeat;
            }
          }
          &.end-treatment-item {
            .breadcrumb-text {
              &:before {
                background: url(../../assets//images/svg-images/end-treatment-nav-active-icon.svg) no-repeat;
              }
            }
          }
          &.active {
            // &:before {
            //     content: none;
            // }
            .breadcrumb-text {
              font-weight: 700;
            }
            .progress-bar-section {
              background: linear-gradient(to right, $secondary-color, $primary-color);
              &::after {
                background: $primary-color;
              }
            }
          }
          .path-name {
            @include sm-min {
              display: none;
            }
          }
          .breadcrumb-text {
            color: $secondary-color;
          }
        }
        .progress-bar-section {
          height: 2px;
          width: 60px;
          background: $secondary-color;
          position: relative;
          margin: 0 20px;
          @include md {
            margin-left: 10px;
          }
          &::before {
            position: absolute;
            content: '';
            height: 6px;
            width: 6px;
            background: $secondary-color;
            border-radius: $rounded-border-radius;
            left: 10px;
            top: -2px;
          }
          &::after {
            position: absolute;
            content: '';
            height: 8px;
            width: 8px;
            background: $secondary-color;
            border-radius: $rounded-border-radius;
            right: 10px;
            top: -3px;
          }
        }
        .progress-bar-section {
          background: $secondary-color;
          @include lg {
            display: none;
          }
        }
      }
    }
    .header-right-panel {
      @include flex-center;
      .notification-panel {
        cursor: pointer;
      }
    }
  }
}

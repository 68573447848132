.form-block {
  .form-group {
    padding-bottom: 30px;
    .form-label {
      font-size: $font14;
      padding-bottom: 5px;
      margin-bottom: 0;
    }
  }
  .auth-tagline-text {
    line-height: $lheight16;
    display: block;
    padding-top: 50px;
  }
  .form-control {
    padding: 9px 14px;
    font-size: $font12;
    &::-webkit-input-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &::-moz-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &:-moz-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &:-webkit-autofill {
      -webkit-text-fill-color: $gray-color !important;
      font-size: $font14;
      font-style: italic;
    }
    &:focus {
      border-color: $primary-color;
      box-shadow: none;
    }
  }
}

// accordion icons

.app-card-heading {
  position: relative;
  padding-left: 40px;
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    height: 30px;
    width: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &.approvals-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/approvals-icon.svg)
        no-repeat;
    }
  }
  &.brief-plan-heading {
    &::before {
      background: url(../../assets/images/svg-images/brief-plan-icon.svg) no-repeat;
    }
  }
  &.clinical-info-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/clinical-info-icon.svg)
        no-repeat;
    }
  }

  &.onco-summary-heading {
    &::before {
      background: url(../../assets/images/svg-images/onco-summary-icon.svg) no-repeat;
    }
  }

  &.fusion-goals-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/fusion-icon.svg)
        no-repeat;
    }
  }

  &.igrt-goals-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/igrt-icon.svg)
        no-repeat;
    }
  }

  &.medical-physics-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/medical-physics-icon.svg)
        no-repeat;
    }
  }

  &.mrn-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/mrn-icon.svg)
        no-repeat;
    }
  }

  &.nursing-tasks-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/nursing-icon.svg)
        no-repeat;
    }
  }

  &.other-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/other-goal-icon.svg)
        no-repeat;
    }
  }

  &.patient-notes-heading {
    &::before {
      background: url(../../assets/images/svg-images/patient-notes-icon.svg) no-repeat;
    }
  }

  &.patientInfo-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/patientInfo-icon.svg)
        no-repeat;
    }
  }

  &.physicianInfo-heading {
    &::before {
      background: url(../../assets/images/svg-images/physician-info-icon.svg) no-repeat;
    }
  }

  &.pre-simulation-heading {
    &::before {
      background: url(../../assets/images/svg-images/pre-simulation-icon.svg) no-repeat;
    }
  }

  &.pre-treatment-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/pre-treatment-icon.svg)
        no-repeat;
    }
  }

  &.prescription-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/prescription-icon.svg)
        no-repeat;
    }
  }

  &.sim-status-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/sim-status-icon.svg)
        no-repeat;
    }
  }

  &.simulation-request-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/simulation-req-icon.svg)
        no-repeat;
    }
  }

  &.treatment-goals-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/treatment-goal-icon.svg)
        no-repeat;
    }
  }

  &.special-treatment-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/treatment-procedure-icon.svg)
        no-repeat;
    }
  }

  &.treatment-team-heading {
    &::before {
      background: url(../../assets/images/svg-images/treatment-team-icon.svg) no-repeat;
    }
  }

  &.course-information-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/course-information-icon.svg)
        no-repeat;
    }
  }
  &.insurance-heading {
    &::before {
      background: url(../../assets/images/svg-images/insurance-icon.svg) no-repeat;
    }
  }
  &.pretreatment-heading {
    &::before {
      background: url(../../assets/images/svg-images/pretreatment-icon.svg) no-repeat;
    }
  }
  &.treatment-planning-heading {
    &::before {
      background: url(../../assets/images/svg-images/treatment-planning-icon.svg) no-repeat;
    }
  }
  &.on-treatment-heading {
    &::before {
      background: url(../../assets/images/svg-images/on-treatment-icon.svg) no-repeat;
    }
  }
  &.appointments-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/appointment-icon.svg)
        no-repeat;
    }
  }
  &.dr-comments-heading {
    &::before {
      background: url(../../assets/images/svg-images/comments-icon.svg) no-repeat;
    }
  }
  &.treatment-details-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/treatment-details-icon.svg)
        no-repeat;
    }
  }
  &.chemotherapy-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/chemotherapy-icon.svg)
        no-repeat;
    }
  }
  &.ancillary-care-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/ancillary-care-icon.svg)
        no-repeat;
    }
  }
  &.on-treatment-issues-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/on-treatment-icon.svg)
        no-repeat;
    }
  }
  &.otv-note-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/otv-note-icon.svg)
        no-repeat;
    }
  }
  &.addition-options-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/additional-option-icon.svg)
        no-repeat;
    }
  }
  &.labs-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/labs-heading-icon.svg)
        no-repeat;
    }
  }
  &.patient-medical-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/patient-medical-icon.svg)
        no-repeat;
    }
  }
  &.on-treatment-issues-heading {
    &::before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/on-treatment-issues-icon.svg)
        no-repeat;
    }
  }
  &.re-simulation-heading {
    &:before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/re-simulation-icon.svg)
        no-repeat;
    }
  }
  &.followup-plan-heading {
    &:before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/followup-plan-icon.svg)
        no-repeat;
    }
  }
  &.followup-studies-heading {
    &:before {
      background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/followup-studies-icon.svg)
        no-repeat;
    }
  }
  &.end-treatment-heading {
    &:before {
      background: url(../../assets/images/svg-images/end-treatment-heading.svg) no-repeat;
    }
  }
  &.stages-heading {
    &:before {
      background: url(../../assets/images/svg-images/stages-icon.svg) no-repeat;
    }
  }
}

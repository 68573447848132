.simulation-main-panel {
  .accordion-main-wrapper {
    padding: 20px 0;
    .accordion-content-wrapper {
      margin-bottom: 20px;
      @include sm {
        margin-bottom: 15px;
      }
      .clinical-info-wrapper {
        .Mui-expanded {
          .add-btn-wrapper {
            display: block !important;
          }
        }
        .pr-35 {
          @include sm {
            padding-right: 0;
          }
        }
        .pr-75 {
          @include lg {
            padding-right: 0;
          }
        }
      }
      .app-card-body {
        @include lg {
          padding-left: 0;
        }
        .treatment-field {
          flex: 1;
          padding-right: 10px;
        }
        .prescription-wrapper {
          flex: 1;
          // padding-right: 10px;
        }
        .action-responsive-wrap {
          max-width: 80px;
          width: 100%;
          &.first-row-wrapper {
            max-width: 68px;
          }
          &.added-row-wrapper {
            max-width: 76px;
          }
        }

        .checkbox-wrapper {
          display: inline-block;
          width: 12%;
          @include laptop {
            width: 20%;
          }
          @include xl-min {
            width: 33%;
          }
          @include sm {
            width: 50%;
          }
          @include mobile {
            width: 100%;
          }
        }
        .clinical-added-content {
          padding: 30px 20px;
          @include box-shadow;
          border-radius: 3px;
        }
        &.treatment-form-wrapper {
          .treatment-form-content {
            .MuiFormControl-root {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
.column-padding {
  padding-right: 5px;
}
.simulation-table {
  .cell-patient {
    width: 9%;
  }

  .cell-clinical-overall-stage {
    width: 7%;
  }

  .cell-diagnosis {
    width: 10%;
  }

  .cell-concurrent-chemotherapy {
    width: 9%;
  }

  .cell-treatment-location {
    width: 13%;
  }

  .cell-treatment-site {
    width: 9%;
  }

  .cell-treatment-intent {
    width: 10%;
  }

  .cell-tretment-modality {
    width: 9%;
  }
  .cell-last-updated {
    width: 8%;
  }

  .cell-simulation-status {
    width: 10%;
  }

  .cell-action {
    width: 7%;
  }
}
.simulation-filters {
  margin: 0 0 15px;
}

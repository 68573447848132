.configuration-main-panel {
  padding: 18px 0;
  .app-card-heading {
    padding-left: 0;
  }
  .MuiBox-root {
    padding: 25px 0;
  }
  .app-card-body {
    padding-left: 0;
  }
  .configuration-edit-panel {
    .delete-icon {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      @include lg {
        top: -8px;
      }
    }
  }
}
.config-data-list {
  &.overflow-responsive {
    @include lg {
      overflow-x: auto;
    }
  }

  .config-data-wrapper {
    min-width: 210px;
    @include lg {
      min-width: 160px;
    }
    .config-data-pill {
      background: $primary-light-color;
      padding: 7px 10px;
      max-width: 120px;
      min-width: 60px;
      border-radius: $larger-border-radius;
      position: relative;
      margin: 0 10px 5px 0;
      .config-data-block {
        .config-data-text {
          font-style: italic;
        }
      }
      .cancel-icon-block {
        .cancel-icon {
          position: absolute;
          right: 7px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
.accordion-content-wrapper {
  .MuiPaper-root {
    // margin-bottom: 20px;
    &:before {
      content: none !important;
    }
  }
  .save-btn-wrapper,
  .add-btn-wrapper {
    @include lg {
      margin-bottom: 10px;
    }
  }
}
.or-text-panel {
  padding: 0 12px;
}
.import-file-box {
  background: $primary-light-color;
  border: 1px dashed $dark-gray-color;
  padding: 40px 20px;
  text-align: center;
  border-radius: $small-border-radius;
  flex: 1;
  height: 260px;
  .or-text-block {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100px;
      height: 1px;
      background: $dark-gray-color;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;
    }
    span {
      position: relative;
      background: $primary-light-color;
      padding: 0 10px;
    }
  }
  .browse-file-block {
    position: relative;
    .file-input-block {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: 120px;
      height: 30px;
      margin: 0 auto;
      z-index: 9;
      opacity: 0;
    }
  }
}
.imported-files-list {
  margin-top: 40px;
  max-height: 430px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px;
  .file-block {
    @include box-shadow;
    padding: 10px 20px;
    height: 90px;
    border-radius: $large-border-radius;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .file-name-block {
      position: relative;
      padding-left: 85px;
      &:before {
        content: '';
        position: absolute;
        width: 68px;
        height: 68px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &.file-xlsx-block {
        &:before {
          background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/file-xlsx-icon.png)
            no-repeat;
        }
      }
    }
  }
}
.configuration-view-panel {
  @include md {
    overflow-x: auto;
  }
  &.common-pill-view {
    .config-data-list {
      .config-data-wrapper {
        min-width: 155px !important;
        .config-data-pill {
          max-width: 145px;
        }
      }
    }
  }
}

.layout-padding {
  padding: 60px 20px 0 100px;
  transition: $transition;
  @include md {
    padding: 80px 10px 0 10px;
  }
  &.active-sidebar-panel {
    padding-left: 200px;
    @include md {
      padding-left: 90px;
    }
    .header-panel {
      width: calc(100% - 220px);
      @include md {
        width: calc(100% - 100px);
      }
    }
  }
}
.app-card-heading {
  color: $primary-color !important;
  font-weight: 600;
}
.app-heading-wrapper {
  background-color: $primary-color;
  border-radius: $larger-border-radius;
  width: 100%;
  max-width: 100%;
  padding: 8px 20px;
  position: sticky;
  top: 60px;
  z-index: 2;
  .form-back-icon {
    cursor: pointer;
  }
}
.app-action-icons {
  cursor: pointer;
  .app-hover-icon {
    display: none;
  }
  &:hover {
    .app-hover-icon {
      display: inline-block;
    }
    .app-icon {
      display: none;
    }
  }
}
.clinical-stage-wrapper {
  padding-right: 220px;
  @include laptop {
    padding-right: 20px;
  }
}
.title-text-checkbox {
  display: flex;
  align-items: center;

  .MuiButtonBase-root {
    padding-right: $pd-0;
    padding-left: $pd-15;
  }
}
// View section
.app-card {
  padding: 20px;
  border-radius: 10px !important;
  @include box-shadow;
  margin-bottom: 20px;
  @include md {
    padding: 20px 10px;
  }
  &.same-height-card {
    height: calc(100% - 20px);
  }
  .edit-mode-block {
    display: none;
    .checkbox-wrapper {
      display: inline-block;
      width: 25%;
      @include laptop {
        width: 33%;
      }
      @include sm {
        width: 50%;
      }
      @include mobile {
        width: 100%;
      }
    }
  }
  &.active-edit-panel {
    .ct-scan-block {
      .checkbox-label {
        display: none;
      }
    }
    .view-mode-block {
      display: none;
    }
    .edit-mode-block {
      display: block;
    }
    .text-label {
      display: none;
      &.heading-label {
        display: block;
      }
    }
    .btn-add-prescription {
      display: block !important;
    }
  }
  .new-entry-panel {
    display: none;
    @include box-shadow;
    border-radius: $small-border-radius;
    padding: 20px 20px 0;
  }
  &.active-add-panel {
    .new-entry-panel {
      display: block;
    }
    .btn-add-prescription {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &.prescription-card {
    .btn-add-prescription {
      display: none;
      .toggle-text {
        display: none;
      }
      .active-add-panel {
        &.default-text {
          display: none;
        }
        &.toggle-text {
          display: block;
        }
      }
    }
  }
  .save-icon-block {
    display: none;
  }
  .active-edit-panel {
    &.edit-icon-block {
      display: none;
    }
    &.save-icon-block {
      display: block;
    }
  }
}
.app-card-body {
  padding-left: 40px;
  @include xl {
    padding-left: 0;
  }
}
.view-card-text-panel {
  margin-bottom: 30px;
}
.text-label {
  color: $gray-color;
  padding-bottom: 5px;
}

// View table
.active-edit-panel {
  .treatment-goals-table-view {
    .header-wrapper {
      .header-heading-text.action-edit-block {
        display: block;
      }
    }
    .body-wrapper {
      .body-wrapper-text {
        &.action-edit-block {
          display: flex;
        }
      }
    }
  }
}
.treatment-goals-table-view {
  .approved-section {
    border: 1px solid green;
  }
  .rejected-section {
    border: 1px solid red;
  }
}
.simulation-view-content {
  .treatment-goals-table-view {
    .header-wrapper {
      .header-heading-text {
        width: 25%;
      }
    }
    .body-wrapper {
      .body-wrapper-text {
        width: 25%;
      }
    }
  }
}
.treatment-goals-table-view {
  @include lg {
    overflow-x: auto;
  }
  .header-wrapper {
    padding: 0 20px;
    @include lg {
      min-width: fit-content;
    }
    .header-heading-text {
      width: 20%;
      &.variation-text {
        width: 25%;
      }
      span {
        font-weight: 500;
      }
      &:last-child {
        max-width: 60px;
      }
      @include lg {
        width: auto;
        min-width: 200px;
        &:last-child {
          min-width: 60px;
        }
      }
      &.action-edit-block {
        display: none;
      }
    }
  }
  .body-wrapper {
    background-color: $primary-light-color;
    border-radius: $larger-border-radius;
    padding: 10px 20px;
    align-items: center;

    @include lg {
      width: fit-content;
    }

    .body-wrapper-text {
      width: 20%;
      &.variation-text {
        width: 25%;
      }
      @include lg {
        width: 200px;
        min-width: 200px;
      }
      &:last-child {
        min-width: 65px;
      }
      &.action-edit-block {
        display: none;
      }
    }
    .MuiFormGroup-root .MuiButtonBase-root.MuiCheckbox-root {
      padding: 0 10px;
    }
  }
  &.chart-table-view {
    .header-wrapper {
      .header-heading-text {
        width: 28%;
        &.priority-text {
          width: 15%;
        }
      }
    }
    .body-wrapper {
      .body-wrapper-text {
        width: 28%;
        &.priority-text {
          width: 15%;
        }
      }
    }
  }
}

//table start
.table-structure {
  border: 1px solid $border-color;
  border-radius: 10px;
  // padding: 15px;
  overflow-x: auto;
  overflow-y: hidden;
  .table-wrapper {
    min-width: fit-content;
    .cell {
      padding-right: 14px;
    }
    .table-header {
      .header-row {
        padding: 15px 20px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 15px;
          bottom: 0;
          width: calc(100% - 30px);
          height: 1px;
          background: $border-color;
        }
        .cell {
          color: $primary-color;
          font-weight: $fweight600;
          font-size: $font12;
          line-height: $lheight16;
        }
      }
    }

    .table-body {
      overflow-x: hidden;
      overflow-y: auto;
      .table-body-scroll {
        min-height: 600px;
        max-height: 600px;
        @include lg {
          min-height: 500px;
        }
        .body-row {
          // border-bottom: 1px solid $border-color;
          position: relative;
          padding: 10px 20px;
          cursor: pointer;
          &:after {
            content: '';
            position: absolute;
            left: 15px;
            bottom: 0;
            width: calc(100% - 30px);
            height: 1px;
            background: $border-color;
          }
          &:last-child {
            border-bottom: none;
          }
          &:active,
          &:hover {
            &:before {
              opacity: 1;
            }
            .cell {
              color: $primary-color;
              &.text-prescription {
                color: $gray-color !important;
              }
            }
            &:before {
              opacity: 1;
            }
          }
          // &:before {
          //   content: "";
          //   position: absolute;
          //   background: $secondary-light-color;
          //   width: 100%;
          //   height: 100%;
          //   top: 0;
          //   left: 0;
          //   z-index: -9;
          //   border-radius: $large-border-radius;
          //   opacity: 0;
          //   transition: $transition;
          // }
          .cell {
            color: $gray-color;
            font-weight: $fweight400;
            font-size: $font12;
            line-height: $lheight16;
            transition: $transition;
          }
        }
      }
    }
  }
}

// Patient Details

.patient-personal-details {
  .profile {
    height: 130px;
    width: 130px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid $secondary-color;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  .details-pill-wrapper {
    border-right: 1px solid $border-color;
    @include lg {
      border-right: 0;
      margin-bottom: 20px;
      .mr-20 {
        margin: 0 auto 10px !important;
      }
    }
    // .details-pill {
    //   padding: 8px;
    //   border-radius: 20px;
    //   background-color: $secondary-light-color;
    //   text-align: center;
    //   margin-bottom: 20px;
    //   @include lg {
    //     margin-bottom: 10px;
    //   }
    //   &:last-child {
    //     margin-bottom: 0;
    //   }
    // }
  }
}

.details-pill {
  padding: 8px;
  border-radius: 20px;
  background-color: $secondary-light-color;
  text-align: center;
  margin-bottom: 20px;
  @include lg {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.last-updated-info {
  background-color: $primary-light-color;
  padding: 6px 10px;
  border-radius: $larger-border-radius;
  margin-left: 10px;
  width: fit-content;
  margin-bottom: 15px;
}

.doctor-initials-wrapper {
  background-color: $secondary-light-color;
  width: 120px;
  max-width: 100%;
  padding: 2px 0;
  border-radius: $large-border-radius $small-border-radius $small-border-radius $large-border-radius;
  align-items: center;
  position: relative;
  span {
    color: $primary-color;
  }
  .doctor-initials {
    border-radius: 50%;
    height: 22px;
    min-width: 22px;
    span {
      font-size: 10px;
    }
    &.physician {
      border: 1px solid #daab00;
      span {
        color: #daab00 !important;
      }
    }
    &.physicist {
      border: 1px solid #023d8e;
      span {
        color: #023d8e !important;
      }
    }
    &.resident {
      border: 1px solid #c78c59;
      span {
        color: #c78c59 !important;
      }
    }
    &.dosimetrist {
      border: 1px solid #74918b;
      span {
        color: #74918b !important;
      }
    }
    &.nurse {
      border: 1px solid $primary-color;
    }
  }
  .away-indicator {
    width: 7px;
    height: 7px;
    border-radius: $rounded-border-radius;
    background: $secondary-color;
    position: absolute;
    right: 5px;
    top: 5px;
    animation: ripple 2s linear infinite;
    transition: $transition;
    // &:before {
    //   content: "";
    //   width: 15px;
    //   height: 15px;
    //   background: $secondary-alpha-color;
    //   position: absolute;
    //   border-radius: $rounded-border-radius;
    //   animation: ripple 2s linear infinite;
    // }
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0rem rgba($secondary-alpha-color, 0.5);
  }
  100% {
    box-shadow: 0 0 0 5px rgba($secondary-alpha-color, 0.5);
  }
}
// .form-subheading-text {
//   background: $primary-light-color;
//   padding: 3px 10px;
//   border-radius: $smaller-border-radius;
// }
.patient-details-pill {
  color: $secondary-color;
  text-align: center;
  max-width: 300px;
  background-color: $secondary-light-color;
  padding: $pd-5;
  border-radius: 20px;
  margin: auto;
  margin-top: $mr-10;
}
.card-subheading {
  position: relative;
  padding-left: 20px;
  &:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background: url(../../assets/images/svg-images/subheading-star-icon.svg) no-repeat;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

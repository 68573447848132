/* Demo Styles */

#content {
  margin: 0 auto;
  padding-bottom: 50px;
  width: 80%;
  max-width: 978px;
}
/* The Loader */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  .no-js & {
    display: none;
  }
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 130px;
  height: 130px;
  margin: -75px 0 0 -75px;
  background: url(../../assets/images/loader_Icon.gif) no-repeat;
  background-size: 130px;

  //   &:before {
  //     content: "";
  //     position: absolute;
  //     top: 5px;
  //     left: 5px;
  //     right: 5px;
  //     bottom: 5px;
  //     border-radius: 50%;
  //     border: 3px solid transparent;
  //     border-top-color: $primary-color;
  //     border-right-color: $primary-color;
  //     animation: spin-reverse .6s linear infinite;
  //   }

  //   &:after {
  //     content: "";
  //     position: absolute;
  //     top: 15px;
  //     left: 15px;
  //     right: 15px;
  //     bottom: 15px;
  //     border-radius: 50%;
  //     border: 3px solid transparent;
  //     border-top-color: #f9c922;
  //     border-right-color: #f9c922;
  //     animation: spin 1.2s linear infinite;
  //   }
}

// Bourbon mixins
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 10;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded styles */

.loaded #loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}

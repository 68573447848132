.form-control {
  background: rgba(255, 255, 255, 0.6);
  input {
    padding: 7px 30px 7px 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    padding-right: 10px;
    &::-webkit-input-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &::-moz-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &:-ms-input-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &:-moz-placeholder {
      color: $gray-color;
      font-size: $font14;
      font-style: italic;
    }
    &:-webkit-autofill {
      -webkit-text-fill-color: $gray-color !important;
      font-size: $font14;
      font-style: italic;
    }
  }
  label {
    line-height: $lheight10;
    font-size: $font14;
    overflow: unset !important;
    color: $gray-color;
    &.Mui-focused {
      color: $primary-color;
      transform: translate(14px, -5px) scale(0.75);
    }
  }
  .MuiOutlinedInput-notchedOutline {
    transition: $transition;
    border-color: $dark-gray-color !important;
    top: 0;
  }
  .MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color;
  }
  .MuiInputBase-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color !important;
    }
    .MuiInputBase-formControl:hover {
      border-color: $primary-color;
    }
    .MuiInputBase-root {
      height: 30px;
      input {
        padding: 0 14px;
      }
    }
  }
}
.MuiOutlinedInput-root {
  border-radius: $small-border-radius !important;
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color !important;
    }
  }
}
.MuiOutlinedInput-notchedOutline {
  border-color: $dark-gray-color !important;
  top: 0 !important;
  legend {
    float: left;
  }
}
.MuiFormControl-root {
  .MuiFormLabel-root {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
    white-space: nowrap !important;
    padding-right: 10px !important;
    padding-bottom: 2px;
  }
}

// Common clinical overall stage

.clinical-stage-filters-wrapper,
.pathological-stage-wrapper {
  .MuiFormControl-root {
    margin-bottom: 10px;
    .MuiOutlinedInput-notchedOutline {
      border-style: none !important;
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-style: solid !important;
      }
    }
    .MuiSelect-select {
      width: 120px;
    }
  }
}
.clinical-overall-stage-wrapper {
  @include laptop {
    margin-bottom: 25px;
  }
  .clinic-marging {
    @include lg {
      margin-bottom: 25px;
    }
  }

  .clinical-overall-stage {
    .MuiFormControl-root {
      position: relative;
      // &::after {
      //     position: absolute;
      //     content: "";
      //     height: 25px;
      //     width: 21px;
      //     right: 0;
      //     background: url(../../assets/images/svg-images/clinical-stage-icon.svg) no-repeat !important;
      // }
      .MuiInputLabel-root {
        top: -3px;
        left: -4px;
        &.Mui-focused {
          top: 14px !important;
          left: 12px !important;
          transform: translate(0) scale(1);
          padding: 0;
        }
      }
      .MuiSelect-select {
        // border: 5px solid $dark-gray-color !important;
        padding-right: 20px !important;
        background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/down-gray-small-arrow.svg)
          no-repeat !important;
        background-position: 70% !important;
        height: 28px !important;
        padding: 0 10px !important;
        border-radius: $rounded-border-radius !important;
        line-height: $lheight30;
      }
      .Mui-focused {
        .MuiSelect-select {
          background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/up-arrow-icon.svg)
            no-repeat !important;
          background-position: 70% !important;
          line-height: $lheight30;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-width: 0 !important;
      }

      .MuiInputBase-root {
        border-radius: $rounded-border-radius !important;
      }
    }
    .MuiBox-root {
      .MuiCircularProgress-root {
        border: 1px solid $dark-gray-color;
        border-radius: 50%;
        color: $primary-color;
        .MuiCircularProgress-svg {
          .MuiCircularProgress-circle {
            stroke-linecap: round;
          }
        }
      }
    }
  }
}

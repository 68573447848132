* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}
html {
  width: 100%;
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
  font-family: $font-stack !important;
  background: $white-color !important;
  color: $primary-color !important;
  padding-right: 0 !important;
  font-family: 'Poppins', sans-serif;
}

#root {
  height: 100vh;
  width: 100%;
}

.App,
.App-wrapper,
.layout-padding {
  height: 100%;
  width: 100%;
}

// Treatment-planning View
.treatment-planning-view-wrapper {
  .app-card {
    .app-heading-wrapper {
      padding-left: 35px;
    }
    .app-card-body {
      @include md {
        padding-left: 0;
        margin-top: 15px;
      }
      .treatment-goals-table-view {
        .approved-section {
          border: 1px solid green;
        }
        .rejected-section {
          border: 1px solid red;
        }
      }
      .view-card-panel.approval-card-panel {
        .MuiFormControl-root {
          margin: 0;
          min-width: 130px;
          max-width: 130px;
          width: auto;
          .MuiInputBase-root {
            .MuiSelect-select {
              line-height: $lheight28;
              padding-left: 0;
            }
            .MuiOutlinedInput-notchedOutline {
              border: 0;
            }
          }
        }
      }
      .edit-mode-block {
        &.small-dropdown-block {
          width: 120px;
        }
      }
    }
  }
}
// Treatment-planning table
.treatment-planning-table {
  &.open-expanded-row {
    .expanded-row {
      display: block !important;
      @include transition;
    }
  }
  .row-expansion {
    .cell-patient {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        background: url(https://ekzeroclient-navigate-oncology-public-assets.s3.ca-central-1.amazonaws.com/images/svg-images/table-row-down-arrow.svg)
          no-repeat;
        background-size: 100% 100%;
        height: 13px;
        width: 11px;
        left: -15px;
        top: 3px;
      }
    }
  }
  .table-wrapper {
    .cell-patient {
      width: 140px;
    }
    .cell-cancer-site {
      width: 140px;
    }
    .cell-diagnosis {
      width: 140px;
    }
    .cell-treatment-team {
      width: 270px;
    }
    .cell-simulation-status {
      width: 110px;
      text-align: center;
    }
    .cell-re-simulation-status {
      width: 110px;
      text-align: center;
    }
    .cell-fusion {
      width: 100px;
      text-align: center;
    }
    .cell-dosimetry-contours {
      width: 120px;
      text-align: center;
    }
    .cell-physician-contours {
      width: 85px;
      text-align: center;
    }
    .cell-treatment-plan {
      width: 100px;
      text-align: center;
    }
    .cell-dosimetry-plan {
      width: 130px;
      text-align: center;
    }
    .cell-physics-consult {
      width: 105px;
      text-align: center;
    }
    .cell-pre-check {
      width: 80px;
      text-align: center;
    }
    .cell-prior-auth {
      width: 80px;
      text-align: center;
    }
    .cell-therapy-chart {
      width: 140px;
      text-align: center;
    }
    .cell-rt-start {
      width: 90px;
      text-align: center;
    }
    .cell-action {
      width: 80px;
      padding-right: 0;
    }
    .expanded-row {
      margin: 10px 0;
      background-color: $secondary-light-color;
      padding: 10px 20px;
      @include box-shadow;
      border-radius: $small-border-radius;
      display: none;
      @include transition;
      // .row-data {
      //     min-width: 200px;
      // }
    }
  }
}
// Treatment-planning  create form
.treatment-form-wrapper {
  overflow: auto;
  padding-left: 0;
  margin-left: 40px;
  .treatment-form-content {
    min-width: 1480px;
    // overflow: auto;
    .MuiFormControl-root {
      margin-bottom: 0 !important;
    }
  }
  .objective-met-wrapper {
    min-width: 155px;
    .app-card-heading {
      padding-left: 10px;
    }
  }
}
.auth-panel {
  .form-label {
    font-size: $font14;
    font-weight: $fweight600;
  }
}

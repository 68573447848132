// Tooltip scss
.MuiTooltip-tooltip {
  background-color: $white-color !important;
  border: 1px solid $primary-color !important;
  color: $primary-color !important;
  position: relative !important;
  padding: 0 5px !important;
  height: 20px !important;
  line-height: 18px !important;
  margin-top: 6px !important;
  font-size: $font10 !important;
  white-space: nowrap;
  min-width: fit-content;
  .MuiTooltip-arrow {
    width: 12px;
    height: 11px;
    &::before {
      position: absolute !important;
      content: '';
      left: 0 !important;
      right: 0 !important;
      margin: auto !important;
      top: -1px !important;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-bottom: 6px solid $white-color !important;
      z-index: 999 !important;
      background-color: transparent !important;
      transform: rotate(0) !important;
    }
    &::after {
      position: absolute !important;
      content: '';
      left: 0 !important;
      right: 0 !important;
      margin: auto !important;
      top: 3px !important;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-bottom: 6px solid $primary-color !important;
      z-index: 99 !important;
      background-color: transparent !important;
    }
  }
}
.MuiPopover-root {
  max-width: 100% !important;
  width: 100% !important;
  &::after,
  &::before {
    content: none !important;
  }
}

// @font-face {
//     font-family: "metropolis-regular";
//     src: url("../../assets/fonts/metropolis.regular.otf");
// }
// @font-face {
//     font-family: "metropolis-semiBold";
//     src: url("../../assets/fonts/metropolis.semi-bold.otf");
// }
// @font-face {
//     font-family: "metropolis-bold";
//     src: url("../../assets/fonts/metropolis.bold.otf");
// }
// @font-face {
//     font-family: "metropolis-black";
//     src: url("../../assets/fonts/metropolis.black.otf");
// }
// @font-face {
//     font-family: "metropolis-metropolis";
//     src: url("../../assets/fonts/metropolis.medium.otf");
// }

h1 {
  font-size: $font50;
  line-height: $lheight54;
}

h2 {
  font-size: $font40;
  line-height: $lheight44;
}

h3 {
  font-size: $font25 !important;
  line-height: $lheight30;
}

h5 {
  font-size: $font20;
  line-height: $lheight24;
}

p {
  font-size: $font16;
  line-height: $lheight20;
}

a:hover {
  text-decoration: none;
}
.meta-info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  padding-right: 5px;
}
a {
  text-decoration: none !important;
}
span,
a {
  font-size: $font12;
  line-height: $lheight18;
  font-family: 'Poppins', sans-serif;
}
div {
  line-height: $lheight18;
}

//font-scss
.font-10 {
  font-size: $font10;
  line-height: $lheight14;
}
.font-12 {
  font-size: $font12;
  line-height: $lheight16;
}
.font-14 {
  font-size: $font14;
  line-height: $lheight18;
  @include sm {
    font-size: $font12;
    line-height: $lheight14;
  }
}
.font-16 {
  font-size: $font16;
  line-height: $lheight20;
  @include md {
    font-size: $font14;
    line-height: $lheight18;
  }
}
.font-18 {
  font-size: $font18;
  line-height: $lheight22;
  @include md {
    font-size: $font16;
    line-height: $lheight20;
  }
  @include mobile {
    font-size: $font14;
    line-height: $lheight18;
  }
}
.font-25 {
  font-size: $font25;
  line-height: $lheight26;
  @include md {
    font-size: $font20;
    line-height: $lheight24;
  }
  @include mobile {
    font-size: $font18;
    line-height: $lheight22;
  }
}
.font-50 {
  font-size: $font50;
  line-height: $lheight54;
}
//font-weight-scss

.font-500 {
  font-weight: 500;
}
.font-600 {
  // font-family: "metropolis-semiBold";
  font-weight: 600;
}
.font-700 {
  // font-family: "metropolis-bold", sans-serif;
  font-weight: 700;
}
.font-900 {
  // font-family: "metropolis-black", sans-serif;
  font-weight: 900;
}

.italic-text {
  font-style: italic;
}

// font-color-scss

.text-white {
  color: $white-color;
}
.text-gray {
  color: $gray-color;
}
.text-primary {
  color: $primary-color !important;
}
.text-secondary {
  color: $secondary-color !important;
}
.text-transition {
  transition: $transition;
}
